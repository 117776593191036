import React from "react";
import Icon from '@material-ui/core/Icon';
import pauseIcon from './images/icon-pause.svg';

export default function Play(props) {
  const { handleClick } = props;
  
  return (
    <div className="downloadBtn" onClick={() => handleClick()}>
    <Icon>
      <img src={pauseIcon} alt="Pause Icon" />
    </Icon>
  </div>
  );
}
