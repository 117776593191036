import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Link from '@material-ui/core/Link';
import TabPanel from './TabPanel';

import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Box,
  Typography,
} from '@material-ui/core';
import AudioPlayer from './AudioPlayer';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
    lineHeight: 'inherit'
  },
  firstRootChild: {
    justifyContent: 'space-between',
    padding: '0px'
  },
  tabList: {
    padding: '0px'
  },
  listItem: {
    padding: '0px',
    margin: '0px'
  },
  textAlignRight: {
    textAlign: 'right',
  },
  staticDrawer: {
    position: 'static',
    marginLeft: theme.spacing(3),
    width: theme.spacing(41.875),
    minHeight: '100vh',
  },
  detailsRoot: {
    padding: theme.spacing(2, 2),
    backgroundColor: '#fff',
  },
  detailsId: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    opacity: '.5',
    '&:hover': {
      opacity: 1,
    },
  },
}));

function CallReportDetails({ report = null, onClose = () => {} }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }
  return (
    <>
      {report ? (
        <Paper className={classes.staticDrawer}>
          <Box display="flex" className={classes.detailsRoot}>
            <Box className={classes.detailsId} onClick={onClose}>
              <CloseIcon />
            </Box>
            <Typography variant="h5" component="span">
              #{report ? report.id : null}
            </Typography>
          </Box>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="INFO" {...a11yProps(0)} />
            <Tab label="CALL DETAILS" {...a11yProps(1)} />
          </Tabs>
          <div className={classes.root}>
            <TabPanel value={value} index={0} className={classes.tabList}>
              <List className={classes.listItem}>
                <ListItem className={classes.firstRootChild}>
                  <AudioPlayer />
                </ListItem>
                <ListItem className={classes.root}>
                  <ListItemText>IP Address: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.ip : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>GA Client ID: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.guid : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Visitor ID: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.visitor_id : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Browser: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.browser : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Device: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.device : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Page Adddress: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.page_address : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Reffering Website: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.referring_website : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Location: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.location : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Select Outcome: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.outcome : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Credits Used: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.credits_used : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Call SID: </ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.call_sid : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Widget ID:</ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.widget_id : null}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Visit On:</ListItemText>
                  <ListItemText className={classes.textAlignRight}>
                    {report ? report.visit_on : null}
                  </ListItemText>
                </ListItem>
                <Divider />
              </List>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <List className={classes.listItem}>
                <ListItem className={classes.root}>
                  <ListItemText>11:20:10</ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>Initial Attempt</ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <Link
                    component="a"
                    variant="body2"
                  >
                    View Call
                  </Link>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>DTMF</ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <ListItemText>DTMF Trace</ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.root}>
                  <Link
                    component="a"
                    variant="body2"
                  >
                    Asterisk call details
                  </Link>
                </ListItem>
                <Divider />
              </List>
            </TabPanel>
          </div>
          </Paper>
      ) : null}
    </>
  );
}

CallReportDetails.propTypes = {
  report: PropTypes.object,
  onClose: PropTypes.func,
};

export default CallReportDetails;
