import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  removeColumnIcon: {
    width: '.8rem',
    height: '.8rem',
    fill: 'red',
    cursor: 'default',
  },
}));

export default function TableHeader({
  title,
  enableRemoveColumnMode,
  onRemove,
}) {
  const classes = useStyles();
  return (
    <Typography
      component="span"
      className={classes.alignItemsCenter}
      fontWeight="fontWeightBold"
    >
      {title}
      {enableRemoveColumnMode ? (
        <CloseIcon onClick={onRemove} className={classes.removeColumnIcon} />
      ) : null}
    </Typography>
  );
}
