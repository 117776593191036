import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@responseiq/ui-kit';
import { makeStyles } from '@material-ui/styles';
import { Add as AddIcon } from '@material-ui/icons';
import callPickerIcon from '../images/columns_picker.svg';

const useStyles = makeStyles(theme => ({
  addColumnButton: {
    margin: '10px 7px',
    backgroundColor: '#62AFF6',
    padding: '13px 12px 10px 15px',
    borderRadius: '2px',
    '&:hover, &:focus': {
      backgroundColor: '#2690f2',
    },
  },
  addColumnIcon: {
    width: '1rem',
    height: '1rem',
    marginLeft: '5px',
  },
  columnsPickerIcon: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
}));

function ColumnsPicker({
  columns,
  onColumnSelect,
  enableRemoveColumnMode,
  toggleColumnsPicker,
}) {
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          paddingLeft: '1rem',
          paddingRight: '1rem',
          boxSizing: 'border-box'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              toggleColumnsPicker(!enableRemoveColumnMode);
            }}
          >
            <img
              className={classes.columnsPickerIcon}
              src={callPickerIcon}
              alt=""
            />
            Columns
          </Button>
        </div>
        <div className="appendButtons">
          {enableRemoveColumnMode
            ? columns.map(column => (
                <Button
                  variant="contained"
                  color="primary"
                  key={column}
                  className={classes.addColumnButton}
                  onClick={() => {
                    onColumnSelect(column);
                  }}
                >
                  {' '}
                  {column} <AddIcon className={classes.addColumnIcon} />
                </Button>
              ))
            : null}
          </div>
      </div>
    </>
  );
}

ColumnsPicker.propTypes = {
  columns: PropTypes.array,
  onColumnSelect: PropTypes.func,
};

export default ColumnsPicker;
