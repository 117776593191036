import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Link } from '@material-ui/core';
import moment from 'moment';
import TableHeader from './TableHeader';
import callOkIcon from '../images/call_ok.svg';
import callFailedIcon from '../images/call_failed.svg';
import badLeadIcon from '../images/bad_lead.svg';

const useStyles = makeStyles(theme => {
  console.log('theme.palette', theme.palette);
  return {
    root: {
      borderRadius: theme.spacing(1),
    },
    callDate: {
      whiteSpace: 'pre',
      textAlign: 'right',
    },
    caption: {
      color: '#646F84',
      opacity: '.6',
      fontSize: '.75rem',
    },
    callStatusIcon: {
      display: 'inline-block',
      width: '1.5rem',
      height: '1.5rem',
    },
    materialTableWrapper: {
      borderRadius: theme.spacing(1),
      overflow: 'hidden',
    },
    badLeadIndicator: {
      display: 'flex',
      alignItems: 'center',
    },
    badLeadText: {
      color: theme.palette.secondary.main,
      marginTop: 1,
    },
    badLeadIcon: {
      marginRight: theme.spacing(1),
    },
  };
});

function getCellStyles(title) {
  console.log(title);
  switch (title) {
    case 'DATE':
      return {
        textAlign: 'right',
      };
    case 'LEAD':
      return {
        textAlign: 'left',
      };
    default:
      return {};
  }
}

function CallReportTable({
  data,
  visibleColumns,
  onColumnRemove,
  onColumnSelect,
  enableRemoveColumnMode = false,
}) {
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const classes = useStyles();
  const columnsToShow = visibleColumns.map(column => {
    return { title: column.toUpperCase(), field: column };
  });

  const getHtml = () => {
    const result = [];
    data.forEach(call => {
      const callStatus =
        call.status === 'failed' ? (
          <img src={callOkIcon} alt="" className={classes.callStatusIcon} />
        ) : (
          <img src={callFailedIcon} alt="" className={classes.callStatusIcon} />
        );
      result.push({
        ...call,
        id: call.id,
        status: callStatus,
        account: (
          <>
            <Typography variant="subtitle2" component="div">
              {call.account}
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.caption}
            >
              CALL BACK
            </Typography>
          </>
        ),
        source: <Link href={call.source}>{call.source}</Link>,
        location: (
          <Typography variant="subtitle2" component="div">
            {call.location}
          </Typography>
        ),
        date: (
          <Typography
            variant="subtitle2"
            component="div"
            className={classes.callDate}
          >
            {moment(call.date).format('YYYY-MM-DD [\r\n]HH:mm:ss')}
          </Typography>
        ),
        lead: (
          <>
            <Typography variant="subtitle2" component="div">
              +44173882716
            </Typography>
            <Typography component="span" className={classes.badLeadIndicator}>
              <img src={badLeadIcon} alt="" className={classes.badLeadIcon} />
              <Typography
                variant="subtitle2"
                component="span"
                className={classes.badLeadText}
              >
                Bad Lead
              </Typography>
            </Typography>
          </>
        ),
      });
    });
    return result;
  };

  return (
    <>
      <MaterialTable
        className={classes.root}
        components={{
          Container: props => (
            <Paper
              {...props}
              elevation={0}
              className={classes.materialTableWrapper}
            />
          ),
          Toolbar: props => <></>,
        }}
        columns={columnsToShow.map(({ title, field }) => {
          return {
            title: (
              <TableHeader
                title={title}
                field={field}
                enableRemoveColumnMode={enableRemoveColumnMode}
                onRemove={() =>
                  onColumnRemove({
                    removedColumn: title,
                    visibleColumns: visibleColumns.filter(
                      c => c !== title.toLowerCase()
                    ),
                  })
                }
              />
            ),
            field: field,
            cellStyle: getCellStyles(title),
            sorting: !enableRemoveColumnMode,
            ...(title === 'DATE' ? { type: 'numeric' } : {}),
          };
        })}
        data={getHtml()}
        title={'Call Report'}
        options={{
          pageSize: recordsPerPage,
          pageSizeOptions: [5, 10, 20, 50],
          headerStyle: { borderBottom: '1px solid #e0e2e6' },
        }}
        onChangeRowsPerPage={pageSize => setRecordsPerPage(pageSize)}
        onRowClick={(e, rowData) => onColumnSelect(rowData.id)}
      />
    </>
  );
}

CallReportTable.propTypes = {
  data: PropTypes.array,
  visibleColumns: PropTypes.array,
  onColumnRemove: PropTypes.func,
  onColumnSelect: PropTypes.func,
  enableRemoveColumnMode: PropTypes.bool,
};

export default CallReportTable;
