import React from 'react';
import './customAudioPlayer/styles.css';
import Audio from './customAudioPlayer/Audio';


function AudioPlayer() {
  return (
    <Audio />
  );
}
export default AudioPlayer;
