import React from "react";
import Icon from '@material-ui/core/Icon';
import playIcon from './images/icon-play.svg';

export default function Play(props) {
  const { handleClick } = props;

  return (
    // <button className="player__button" >
    //   <PlayArrowOutlined />
    // </button>
     <div className="downloadBtn" onClick={() => handleClick()}>
        <Icon>
          <img src={playIcon} alt="Play Icon" />
        </Icon>
      </div>
  );
}
