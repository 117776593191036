import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { buttonStyles } from '../../jss/buttonStyle';

const UIButton = props => {
  const { classes, color, onClick } = props;
  if (color === 'cancel') {
    return (
      <Button className={classes.cancelBtn} disableRipple onClick={onClick}>
        Cancel
      </Button>
    );
  }
  if (color === 'primary') {
    return (
      <Button className={classes.primaryBtn} disableRipple onClick={onClick}>
        update
      </Button>
    );
  }
};

UIButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(buttonStyles)(UIButton);
