import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { riqTheme } from '@responseiq/ui-kit';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import DatePicker from '../components/DatePicker';
import ColumnsPicker from '../components/ColumnsPicker';
import CallReportTable from '../components/CallReportTable';
import CallReportDetails from '../components/CallReportDetails';
import { getFormatedDate, getLastMonthDates } from '../utils/helper';

import { CALL_REPORTS_FIXTURE } from '../CALL_REPORTS_FIXTURE';
import { ThemeProvider } from '@material-ui/styles';

const callsData = [];

CALL_REPORTS_FIXTURE.forEach(callLog => {
  let call = {
    id: callLog['Calllog']['id'],
    status: callLog['Calllog']['lead_dial_call_status'],
    account: callLog['Widget']['title'] || callLog['Widget']['widget_url'],
    source: callLog['Calllog']['visitor_url'],
    agent: `${callLog['Agent']['first_name']} ${callLog['Agent']['last_name']}`,
    lead: callLog['Lead']['full_name'] || callLog['Lead']['emailaddress'],
    location: `${callLog['Visitor']['city']} ${
      callLog['Visitor']['city'] ? ',' : ''
    } ${callLog['Visitor']['countryname']} `,
    duration: callLog['Calllog']['retry_response_time'],
    date: callLog['Calllog']['created'] || callLog['Lead']['ScheduledCall'],
    ip: callLog['Visitor']['ipaddress'],
    visitor_id: callLog['Visitor']['id'],
    browser: callLog['Visitor']['browser'],
    device: callLog['Visitor']['device'],
    referring_website: 'Direct',
    country: callLog['Visitor']['countryname'],
    visit_on: callLog['Calllog']['created'],
    outcome: '',
    credits_used: callLog['Calllog']['total_credits'],
    call_sid: callLog['Calllog']['parentcallsid'],
    widget_id: callLog['Calllog']['widget_id'],
    agent_recording: callLog['Calllog']['recordingurl'],
  };
  callsData.push(call);
});

console.log(callsData);

const AVAILABLE_COLUMNS = [
  'id',
  'status',
  'account',
  'source',
  'agent',
  'lead',
  'location',
  'duration',
  'date',
];

const INITIALLY_VISIBLE_COLUMNS = [
  'id',
  'status',
  'account',
  'source',
  'agent',
  'location',
  'duration',
  'date',
];

const useStyles = makeStyles(theme => ({
  rootContainer: {
    display: 'flex',
    padding: theme.spacing(0, 2, 2),
  },
  callReportContainer: {
    flex: '1 1 100%',
  },
  callDetailsContainer: {},
}));

function CallReport() {
  const defaultDates = getLastMonthDates();
  const defaultRange = {
    startDate: getFormatedDate(defaultDates.startDate),
    endDate: getFormatedDate(defaultDates.endDate),
  };

  const [visibleColumns, setVisibleColumns] = useState(
    INITIALLY_VISIBLE_COLUMNS
  );
  const [selectedReport, setSelectedReport] = useState(null);
  const [enableRemoveColumnMode, setEnableRemoveColumnMode] = useState(false);
  const [filterRange, setFilterRange] = useState(defaultRange);

  const classes = useStyles();
  const filterDataHandle = () => {
    const newFilteredData = callsData.filter(item => {
      const cDate = getFormatedDate(item.date);
      const sDate = getFormatedDate(filterRange.startDate);
      const eDate = getFormatedDate(filterRange.endDate);
      return moment(cDate).isBetween(sDate, eDate);
    });
    return newFilteredData;
  };

  return (
    <ThemeProvider theme={riqTheme}>
      <div className="columnDiv">
        <Typography variant="h4">Call Report</Typography>
        <DatePicker
          onSelectionChange={(from, to) => {
            setFilterRange({ startDate: from, endDate: to });
          }}
        />
      </div>
      <ColumnsPicker
        columns={AVAILABLE_COLUMNS.filter(x => !visibleColumns.includes(x))}
        enableRemoveColumnMode={enableRemoveColumnMode}
        toggleColumnsPicker={setEnableRemoveColumnMode}
        onColumnSelect={column =>
          setVisibleColumns([...visibleColumns, column])
        }
      />
      <div className={classes.rootContainer}>
        <div className={classes.callReportContainer}>
          <CallReportTable
            data={filterDataHandle()}
            visibleColumns={visibleColumns}
            enableRemoveColumnMode={enableRemoveColumnMode}
            onColumnRemove={({ removedColumn, visibleColumns }) => {
              setVisibleColumns(visibleColumns);
            }}
            onColumnSelect={id => {
              setSelectedReport(callsData.find(report => report.id === id));
            }}
          />
        </div>
        <div className={classes.callDetailsContainer}>
          <CallReportDetails
            report={selectedReport}
            onClose={() => {
              setSelectedReport(null);
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default CallReport;
