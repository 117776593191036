import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import {
  getToday,
  getYesterday,
  getLastDates,
  getLastWeekDates,
  getLastMonthDates,
  getWeekDates,
  getCurMonthDates,
} from '../utils/helper';
import classnames from 'classnames';

import UIButton from './Button';
import 'react-dates/initialize';
import './styles/datePicker.css';

class DateRangeCalendar extends React.PureComponent {
  state = {
    startDate: moment().subtract(1, 'months'),
    endDate: moment(),
    focusedInput: null,
  };

  handleDateChange = ({ startDate, endDate }) =>
    this.setState({ startDate, endDate });
  handleFocusChange = focusedInput => {
    if (focusedInput) {
      this.setState({ focusedInput });
    }
  };
  manageDates = (flag = 'lastWeek') => {
    if (flag === 'today') {
      this.setState({
        startDate: getToday(),
        endDate: getToday(),
      });
    } else if (flag === 'yesterday') {
      this.setState({
        startDate: getYesterday(),
        endDate: getYesterday(),
      });
    } else if (flag === 'last7days') {
      const { startDate, endDate } = getLastDates(7);
      this.setState({
        startDate,
        endDate,
      });
    } else if (flag === 'last14days') {
      const { startDate, endDate } = getLastDates(14);
      this.setState({
        startDate,
        endDate,
      });
    } else if (flag === 'last30days') {
      const { startDate, endDate } = getLastDates(30);
      this.setState({
        startDate,
        endDate,
      });
    } else if (flag === 'curweek') {
      const { startDate, endDate } = getWeekDates();
      this.setState({
        startDate,
        endDate,
      });
    } else if (flag === 'lastweek') {
      const { startDate, endDate } = getLastWeekDates();
      this.setState({
        startDate,
        endDate,
      });
    } else if (flag === 'curmonth') {
      const { startDate, endDate } = getCurMonthDates();
      this.setState({
        startDate,
        endDate,
      });
    } else if (flag === 'lastMonth') {
      const { startDate, endDate } = getLastMonthDates();
      this.setState({
        startDate,
        endDate,
      });
    }
  };

  handleSubmitDates = ({ startDate, endDate }) => {
    const { onSelectionChange } = this.props;
    this.setState({ focusedInput: null });
    onSelectionChange(startDate, endDate);
  };

  manageClose = () => {
    const { focusedInput } = this.state;
    this.setState({ focusedInput: focusedInput ? null : 'startDate' });
  };

  render() {
    const { startDate, endDate, focusedInput } = this.state;
    return (
      <>
        <div className="calenderMaincontainer">
          <DateRangePicker
            endDate={endDate}
            endDateId="endDate"
            appendToBody={true}
            monthFormat="MMMM"
            customArrowIcon="-"
            customCloseIcon="X"
            showDefaultInputIcon={false}
            focusedInput={focusedInput}
            onDatesChange={this.handleDateChange}
            onFocusChange={this.handleFocusChange}
            startDate={startDate}
            startDateId="startDate"
            displayFormat="DD MMM YYYY"
            calendarInfoPosition="after"
            numberOfMonths={2}
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel={true}
            onClose={() => this.setState({ focusedInput })}
            keepOpenOnDateSelect
            renderCalendarInfo={() => {
              return (
                <div className="divider-comp">
                  <div>
                    <h5 className="head">Presets</h5>
                    <ul>
                      <li
                        onClick={() => {
                          this.manageDates('lifetime');
                        }}
                      >
                        Lifetime
                      </li>
                      <li
                        onClick={() => {
                          this.manageDates('today');
                        }}
                      >
                        Today
                      </li>
                      <li
                        onClick={() => {
                          this.manageDates('yesterday');
                        }}
                      >
                        Yesterday
                      </li>
                      <li
                        onClick={() => {
                          this.manageDates('last7days');
                        }}
                      >
                        Last 7 days
                      </li>
                      <li
                        onClick={() => {
                          this.manageDates('last14days');
                        }}
                      >
                        Last 14 days
                      </li>
                      <li
                        onClick={() => {
                          this.manageDates('last30days');
                        }}
                      >
                        Last 30 days
                      </li>
                      <li
                        onClick={() => {
                          this.manageDates('curweek');
                        }}
                      >
                        This week
                      </li>
                      <li
                        onClick={() => {
                          this.manageDates('lastweek');
                        }}
                      >
                        Last week
                      </li>
                      <li
                        onClick={() => {
                          this.manageDates('curmonth');
                        }}
                      >
                        This month
                      </li>
                      <li
                        onClick={() => {
                          this.manageDates('lastMonth');
                        }}
                      >
                        Last month
                      </li>
                    </ul>
                    <div className="updateContainer">
                      <UIButton
                        variant="contained"
                        onClick={() => this.setState({ focusedInput: null })}
                        color="cancel"
                      >
                        Cancel
                      </UIButton>
                      <UIButton
                        variant="contained"
                        color="primary"
                        onClick={dateObj => {
                          this.handleSubmitDates({ startDate, endDate });
                        }}
                      >
                        Update
                      </UIButton>
                    </div>
                  </div>
                </div>
              );
            }}
          />
          <button
            className={classnames('iconArrow', { iconup: focusedInput })}
            onClick={this.manageClose}
          ></button>
        </div>
      </>
    );
  }
}

DateRangeCalendar.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
};

export default DateRangeCalendar;
