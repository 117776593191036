import React from "react";
import Icon from '@material-ui/core/Icon';
import Play from "./Play";
import Pause from "./Pause";
import Bar from "./Bar";
import downloadIcon from './images/icon-download.svg';
import useAudioPlayer from './useAudioPlayer';

function Audio() {
  const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer();

  return (
    <div className="player">
      <audio id="audio">
      <source src="https://freemusicdownloads.world/wp-content/uploads/2017/05/Taylor-Swift-Shake-It-Off.mp3" />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className="controls">
        <div className="playBtn">
          {playing ? 
            <Pause handleClick={() => setPlaying(false)} /> :
            <Play handleClick={() => setPlaying(true)} />
          }
        </div>
        <div className="bar">
          <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)}/>
        </div>
        <div className="downloadBtn">
          <Icon>
            <img src={downloadIcon} alt="download icon" />
          </Icon>
        </div>
      </div>
    </div>
  );
}

export default Audio;
