const baseStyle = {
    minWidth: '77px',
    padding: '10px 30px 8px',
    letterSpacing: '0.438542px',
    borderRadius: 4,
    border: 0,
    boxShadow: '0px 2.33889px 4.67778px rgba(84, 110, 122, 0.24)',
};
export const buttonStyles = {
    primaryBtn: {
        ...baseStyle,
        background: '#62aff6',
        color: '#fff',
        '&:hover': {
        background: '#62aff6'
        },
        label: {
        textTransform: 'uppercare'
        }
    },
    cancelBtn: {
        ...baseStyle,
        background: 'transparent',
        color: '#263238',
        boxShadow: 'none',
        marginLeft: '10px',
        '&:hover': {
        background: 'transparent'
        },
        label: {
        textTransform: 'uppercare'
        }
    }
};