export const  CALL_REPORTS_FIXTURE = [
  {
    "Calllog": {
      "id": "511",
      "company_id": "2299",
      "lead_id": "511",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072755",
      "visitor_url_id": "0",
      "referrer_id": "7379800",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1562245948369",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://127.0.0.1:9999/test/widget",
      "querystring": null,
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "073fa28a81b0a4a1e70ea032b53b112d",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-07-04 18:42:31",
      "created": "2019-07-04 18:42:28",
      "leadcreated": "2019-07-04 18:42:28",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-07-04 18:42:28",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": null,
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": null,
      "riq_referrer": null,
      "riq_current_url": null
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "511",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "7379800",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072755",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": null,
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+917837317983",
      "phone_type": null,
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "0",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-07-04 18:42:28",
      "starttime": "18:42:28",
      "endtime": "18:42:28",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-07-04 18:42:28",
      "created": "2019-07-04 18:42:28",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": "",
      "postcode": "",
      "country": "",
      "emailaddress": "",
      "question_first": "",
      "question_second": "",
      "question_third": "",
      "lead_reference_number": "",
      "distribution_ID": "",
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072755",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "7379800",
      "session_id": null,
      "ipaddress": "f528764d624db129b32c21fbca0cb8d6",
      "clicks": "0",
      "browser": "Firefox",
      "city": "API",
      "device": "Desktop",
      "region": "API",
      "countrycode": "API",
      "countryname": "API",
      "timezone": "Asia/Kolkata",
      "timezone_name": "Kolkata",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "0",
      "browsersession": null,
      "googleanalatics": null,
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:67.0) Gecko/20100101 Firefox/67.0",
      "visitor_url": "http://127.0.0.1:9999/test/widget",
      "querystring": null,
      "updated": "2019-07-04 18:42:28",
      "created": "2019-07-04 18:42:28",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": "7379800",
      "company_id": "2299",
      "type_id": "259508",
      "url": "API",
      "count": "1",
      "created": "2019-03-06 09:06:02",
      "RefferType": { "id": "259508", "company_id": "2299", "name": "API" }
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "510",
      "company_id": "2299",
      "lead_id": "510",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072753",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559824520752",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "e97e4ce5f2cb615f9e0df64b936d9256",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-06 13:54:21",
      "created": "2019-06-06 13:54:21",
      "leadcreated": "2019-06-06 13:54:21",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-06 13:54:21",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "initiated",
      "agent_sip_status": "",
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "510",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072753",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559824520752",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "0",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 13:54:22",
      "starttime": "13:54:21",
      "endtime": "13:54:21",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 13:54:21",
      "created": "2019-06-06 13:54:21",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072753",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559824520752",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559824520263",
      "googleanalatics": "1716396086.1559824590",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 13:54:22",
      "created": "2019-06-06 13:35:20",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "508",
      "company_id": "2299",
      "lead_id": "508",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "59015",
      "vistors_id": "2170072753",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": null,
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "",
      "dialcallstatus": "Scheduled",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-06 13:38:18",
      "created": "2019-06-06 13:38:18",
      "leadcreated": "2019-06-06 13:38:18",
      "lead_type": "Scheduled",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-06 13:38:18",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": null,
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": null,
      "riq_current_url": null
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "508",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "59015",
      "vistors_id": "2170072753",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": null,
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "0",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 13:38:18",
      "starttime": "13:38:18",
      "endtime": "13:38:18",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 13:38:18",
      "created": "2019-06-06 13:38:18",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": [
        {
          "id": "120879",
          "lead_id": "508",
          "company_id": "2299",
          "leadsource_id": "0",
          "widget_id": "2004",
          "agent_id": "59015",
          "scheduledby_id": "2299",
          "schdeuled_date": "2019-06-06",
          "scheduled_time": "13:45:00",
          "scheduled_time_text": null,
          "status": "0",
          "sms_sent_time": "0000-00-00 00:00:00",
          "sms_receive_time": "0000-00-00 00:00:00",
          "sent": "0",
          "follow_up_call": "1",
          "follow_up_call_mail": "0",
          "created": "2019-06-06 13:38:18",
          "Company": {
            "id": "2299",
            "cust": null,
            "cust_no": "0",
            "user_id": "0",
            "accents": "en-GB",
            "voice_type": "man",
            "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
            "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
            "twilio_account_status": "0",
            "plivo_accountsid": null,
            "plivo_auth_token": null,
            "plivo_account_status": "0",
            "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
            "api_type": "2",
            "carrier": "idt",
            "lead_carrier": "idt",
            "username": "",
            "name": "Pankaj Kumar",
            "first_name": "Pankaj",
            "last_name": "Kumar",
            "company_name": "ogs",
            "friendly_name": null,
            "date_created": null,
            "email": "pankaj@responseiq.com",
            "country_code": "44",
            "country_id": "225",
            "phone": "6463970198",
            "company_phone": "9646977254",
            "company_phone_country": "91",
            "company_extention": "1",
            "company_delay": "5",
            "title": "ogs",
            "website": "http://127.0.0.1:9999/test/widget",
            "password": "",
            "address_1": "",
            "zip_code": "",
            "logo": "",
            "call": "1",
            "country_of_business": "uk",
            "financial_email": "",
            "vat_number": null,
            "status": "1",
            "master_status": "1",
            "master_company_id": "0",
            "location_enable": "0",
            "department_enable": "0",
            "created": "2019-03-01 07:39:39",
            "is_trashed": "0",
            "is_type": "0",
            "selector": "Agent",
            "currency": "usd",
            "company_active_status": "1",
            "send_thanks_mail": "1",
            "step": "6",
            "manual_install": "0",
            "lead_revisit_alert": "0",
            "company_activation_reminder": "0",
            "became_customer": null,
            "three_call_missed": "0000-00-00 00:00:00",
            "agents_send_login_enabled": "0",
            "twilio_account_suspend": "1",
            "plivo_account_suspend": "1",
            "tc_confirmed": "1",
            "gdpr_enable": "0",
            "widget_country_code_edit": "1",
            "cancel_subscription_enable": "1",
            "api_enable": "1",
            "ilr_sms_module_enable": "0",
            "company_disable_widget": "0",
            "no_call_notify": "0",
            "company_daily_summary": "0",
            "company_weekly_summary": "0",
            "company_monthly_summary": "0",
            "company_daily_summary_emails": "pankaj@responseiq.com",
            "company_weekly_summary_emails": "pankaj@responseiq.com",
            "company_monthly_summary_emails": "pankaj@responseiq.com",
            "company_daily_csv": "0",
            "company_daily_csv_emails": "pankaj@responseiq.com",
            "company_disable_widget_ie": "0",
            "reply_by_sms_to_claim_email": "0",
            "google_analytics": "1",
            "querystring_enabled": "0",
            "company_plain_email_enabled": "0",
            "lead_name_play_enabled": "0",
            "is_attempts_to_exit_enabled": "0",
            "vodafone_requestid": "0",
            "country_multipliers": "1",
            "live_google_analytics": "1",
            "ilr_reports": "1",
            "double_layer_ivr": "1",
            "double_layer_ivr_delay": "5",
            "double_layer_ivr_ext": "2",
            "over_use_credits": "0",
            "thank_you_url_redirect": "0",
            "show_autopopup_once_enabled": "0",
            "send_sms_after_one_minutes_call_enabled": "0",
            "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
            "name_enable": "0",
            "fallback_number_enabled": "0",
            "fallback_number_country_code": "",
            "fallback_number": null,
            "fallback_exten": null,
            "fallback_delay": "0",
            "call_scheduled_canceled_setting": "0",
            "modified": "2019-06-28 09:54:27",
            "updated": "2019-06-28 09:54:27",
            "salt": "322ce115245499ec9ee357e63a5e6860",
            "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
            "2fa": "0",
            "aging": "0",
            "password_modified": "2019-03-01 07:39:39",
            "password_modified_ip": null,
            "password_strength": null,
            "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
            "email_enable": "1",
            "outcome_tracking": "1",
            "scheduled_mail_daily_report": "0",
            "scheduled_email_report": "",
            "tracking_number": "0",
            "dont_show_widget_in_mobile": "1",
            "show_time_changes_schedule": "0",
            "company_outcomeweekly_summary": "1",
            "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
            "three_layer_ivr": "0",
            "three_layer_ivr_delay": "0",
            "three_layer_ivr_ext": "0",
            "automated_followup_call": "1",
            "api_forward_leademail": "1",
            "custom_widget_js_file": "0",
            "timeline_tracking": "0",
            "mime_type": "0"
          },
          "Lead": {
            "id": "508",
            "company_id": "2299",
            "widget_id": "2004",
            "referrer_id": "0",
            "agent_id": "0",
            "called_agent_id": "59015",
            "vistors_id": "2170072753",
            "visitor_url_id": "0",
            "stratege_id": "0",
            "leadsource_id": "0",
            "department_id": "0",
            "session_id": null,
            "name": "",
            "username": "",
            "email": "",
            "fromemail": null,
            "phone_country": "91",
            "country_name": "IN",
            "phone": "+919646977254",
            "phone_type": "",
            "bookingno": null,
            "template_capture_number": "",
            "moveid": null,
            "requestid": null,
            "subject": "",
            "message": "",
            "contact_pref": "",
            "registration": "",
            "vehicle_details": "",
            "color": "",
            "price": "",
            "type": "0",
            "appointmentset": "0",
            "retry_strategy": "0",
            "location": "0",
            "missed": "0",
            "leadbody": "",
            "finalbody": "",
            "json_arr": "",
            "updated": "2019-06-06 13:38:18",
            "starttime": "13:38:18",
            "endtime": "13:38:18",
            "notify": "0",
            "notify_agent_id": "0",
            "no_click_notify": "0",
            "is_deleted": "0",
            "out_of_hours_status": "0",
            "revisit_checked": "0",
            "lead_revisits_counts": "0",
            "leadcallcompleted": "0",
            "leadcreated": "2019-06-06 13:38:18",
            "created": "2019-06-06 13:38:18",
            "sms_leads_logs_id": "0",
            "is_lead_type": "0",
            "full_name": null,
            "postcode": null,
            "country": null,
            "emailaddress": null,
            "question_first": null,
            "question_second": null,
            "question_third": null,
            "lead_reference_number": null,
            "distribution_ID": null,
            "lead_created_gmt": null,
            "Company": {
              "id": "2299",
              "cust": null,
              "cust_no": "0",
              "user_id": "0",
              "accents": "en-GB",
              "voice_type": "man",
              "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
              "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
              "twilio_account_status": "0",
              "plivo_accountsid": null,
              "plivo_auth_token": null,
              "plivo_account_status": "0",
              "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
              "api_type": "2",
              "carrier": "idt",
              "lead_carrier": "idt",
              "username": "",
              "name": "Pankaj Kumar",
              "first_name": "Pankaj",
              "last_name": "Kumar",
              "company_name": "ogs",
              "friendly_name": null,
              "date_created": null,
              "email": "pankaj@responseiq.com",
              "country_code": "44",
              "country_id": "225",
              "phone": "6463970198",
              "company_phone": "9646977254",
              "company_phone_country": "91",
              "company_extention": "1",
              "company_delay": "5",
              "title": "ogs",
              "website": "http://127.0.0.1:9999/test/widget",
              "password": "",
              "address_1": "",
              "zip_code": "",
              "logo": "",
              "call": "1",
              "country_of_business": "uk",
              "financial_email": "",
              "vat_number": null,
              "status": "1",
              "master_status": "1",
              "master_company_id": "0",
              "location_enable": "0",
              "department_enable": "0",
              "created": "2019-03-01 07:39:39",
              "is_trashed": "0",
              "is_type": "0",
              "selector": "Agent",
              "currency": "usd",
              "company_active_status": "1",
              "send_thanks_mail": "1",
              "step": "6",
              "manual_install": "0",
              "lead_revisit_alert": "0",
              "company_activation_reminder": "0",
              "became_customer": null,
              "three_call_missed": "0000-00-00 00:00:00",
              "agents_send_login_enabled": "0",
              "twilio_account_suspend": "1",
              "plivo_account_suspend": "1",
              "tc_confirmed": "1",
              "gdpr_enable": "0",
              "widget_country_code_edit": "1",
              "cancel_subscription_enable": "1",
              "api_enable": "1",
              "ilr_sms_module_enable": "0",
              "company_disable_widget": "0",
              "no_call_notify": "0",
              "company_daily_summary": "0",
              "company_weekly_summary": "0",
              "company_monthly_summary": "0",
              "company_daily_summary_emails": "pankaj@responseiq.com",
              "company_weekly_summary_emails": "pankaj@responseiq.com",
              "company_monthly_summary_emails": "pankaj@responseiq.com",
              "company_daily_csv": "0",
              "company_daily_csv_emails": "pankaj@responseiq.com",
              "company_disable_widget_ie": "0",
              "reply_by_sms_to_claim_email": "0",
              "google_analytics": "1",
              "querystring_enabled": "0",
              "company_plain_email_enabled": "0",
              "lead_name_play_enabled": "0",
              "is_attempts_to_exit_enabled": "0",
              "vodafone_requestid": "0",
              "country_multipliers": "1",
              "live_google_analytics": "1",
              "ilr_reports": "1",
              "double_layer_ivr": "1",
              "double_layer_ivr_delay": "5",
              "double_layer_ivr_ext": "2",
              "over_use_credits": "0",
              "thank_you_url_redirect": "0",
              "show_autopopup_once_enabled": "0",
              "send_sms_after_one_minutes_call_enabled": "0",
              "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
              "name_enable": "0",
              "fallback_number_enabled": "0",
              "fallback_number_country_code": "",
              "fallback_number": null,
              "fallback_exten": null,
              "fallback_delay": "0",
              "call_scheduled_canceled_setting": "0",
              "modified": "2019-06-28 09:54:27",
              "updated": "2019-06-28 09:54:27",
              "salt": "322ce115245499ec9ee357e63a5e6860",
              "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
              "2fa": "0",
              "aging": "0",
              "password_modified": "2019-03-01 07:39:39",
              "password_modified_ip": null,
              "password_strength": null,
              "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
              "email_enable": "1",
              "outcome_tracking": "1",
              "scheduled_mail_daily_report": "0",
              "scheduled_email_report": "",
              "tracking_number": "0",
              "dont_show_widget_in_mobile": "1",
              "show_time_changes_schedule": "0",
              "company_outcomeweekly_summary": "1",
              "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
              "three_layer_ivr": "0",
              "three_layer_ivr_delay": "0",
              "three_layer_ivr_ext": "0",
              "automated_followup_call": "1",
              "api_forward_leademail": "1",
              "custom_widget_js_file": "0",
              "timeline_tracking": "0",
              "mime_type": "0"
            },
            "Widget": {
              "id": "2004",
              "company_id": "2299",
              "widget_token": "9U3VLWE2R11P",
              "apikey": "RIQ130954042004",
              "title": "",
              "widget_url": "http://127.0.0.1:9999/test/widget",
              "logo": null,
              "port": "http://",
              "select_agent_or_team": "0",
              "status": "1",
              "install_status": "0",
              "installed_mail_sent": "1",
              "uninstall_mail_sent": "0",
              "installed_date": "2019-03-01 08:12:07",
              "visit_customize_status": "1",
              "agree_call_recording": "1",
              "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
              "welcometextschedule": null,
              "welcometext_animate_1": "0",
              "welcometextschedule_animate_2": "0",
              "widget_country_code_edit": "0",
              "created": "2019-03-01 07:40:37",
              "first_time_installation": "1",
              "call_schedule_button_mailsent": "1",
              "widgets_departments": "0",
              "visitor_time_call_enabled": "1",
              "visitor_duplicate_call_minutes": "1",
              "thank_you_url_redirect": "0",
              "thank_you_url": null,
              "webhook_url_port": "",
              "webhook_url": "",
              "webhook_events": "",
              "number_lookup": "2",
              "widget_file": "default"
            },
            "IqSource": [],
            "RetryResponseAttempt": [],
            "ScheduledCall": [
              {
                "id": "120879",
                "lead_id": "508",
                "company_id": "2299",
                "leadsource_id": "0",
                "widget_id": "2004",
                "agent_id": "59015",
                "scheduledby_id": "2299",
                "schdeuled_date": "2019-06-06",
                "scheduled_time": "13:45:00",
                "scheduled_time_text": null,
                "status": "0",
                "sms_sent_time": "0000-00-00 00:00:00",
                "sms_receive_time": "0000-00-00 00:00:00",
                "sent": "0",
                "follow_up_call": "1",
                "follow_up_call_mail": "0",
                "created": "2019-06-06 13:38:18"
              }
            ]
          },
          "Agent": {
            "id": "59015",
            "company_id": "2299",
            "call_order": "1",
            "first_name": "Ajay",
            "last_name": "",
            "user_name": "",
            "email": "ajay@responseiq.com",
            "phone_country": "44",
            "phone": "7355828310",
            "password": "",
            "image": "",
            "positions": "",
            "country": "",
            "state": "",
            "city": "",
            "zipcode": "",
            "address": "",
            "status": "1",
            "email_info": "0",
            "created": "2019-03-12 12:45:19",
            "mail_notify": "0",
            "extension_status": "0",
            "verify_status": "0",
            "verified_date": "0000-00-00 00:00:00",
            "extention": "321",
            "delay": "0",
            "agent_extention": null,
            "team_incoming_number_status": "0",
            "login_status": "0",
            "sms": "0",
            "access_level": "0",
            "last_login": "2019-03-12 12:45:19",
            "first_notification": "0000-00-00",
            "Company": {
              "id": "2299",
              "cust": null,
              "cust_no": "0",
              "user_id": "0",
              "accents": "en-GB",
              "voice_type": "man",
              "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
              "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
              "twilio_account_status": "0",
              "plivo_accountsid": null,
              "plivo_auth_token": null,
              "plivo_account_status": "0",
              "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
              "api_type": "2",
              "carrier": "idt",
              "lead_carrier": "idt",
              "username": "",
              "name": "Pankaj Kumar",
              "first_name": "Pankaj",
              "last_name": "Kumar",
              "company_name": "ogs",
              "friendly_name": null,
              "date_created": null,
              "email": "pankaj@responseiq.com",
              "country_code": "44",
              "country_id": "225",
              "phone": "6463970198",
              "company_phone": "9646977254",
              "company_phone_country": "91",
              "company_extention": "1",
              "company_delay": "5",
              "title": "ogs",
              "website": "http://127.0.0.1:9999/test/widget",
              "password": "",
              "address_1": "",
              "zip_code": "",
              "logo": "",
              "call": "1",
              "country_of_business": "uk",
              "financial_email": "",
              "vat_number": null,
              "status": "1",
              "master_status": "1",
              "master_company_id": "0",
              "location_enable": "0",
              "department_enable": "0",
              "created": "2019-03-01 07:39:39",
              "is_trashed": "0",
              "is_type": "0",
              "selector": "Agent",
              "currency": "usd",
              "company_active_status": "1",
              "send_thanks_mail": "1",
              "step": "6",
              "manual_install": "0",
              "lead_revisit_alert": "0",
              "company_activation_reminder": "0",
              "became_customer": null,
              "three_call_missed": "0000-00-00 00:00:00",
              "agents_send_login_enabled": "0",
              "twilio_account_suspend": "1",
              "plivo_account_suspend": "1",
              "tc_confirmed": "1",
              "gdpr_enable": "0",
              "widget_country_code_edit": "1",
              "cancel_subscription_enable": "1",
              "api_enable": "1",
              "ilr_sms_module_enable": "0",
              "company_disable_widget": "0",
              "no_call_notify": "0",
              "company_daily_summary": "0",
              "company_weekly_summary": "0",
              "company_monthly_summary": "0",
              "company_daily_summary_emails": "pankaj@responseiq.com",
              "company_weekly_summary_emails": "pankaj@responseiq.com",
              "company_monthly_summary_emails": "pankaj@responseiq.com",
              "company_daily_csv": "0",
              "company_daily_csv_emails": "pankaj@responseiq.com",
              "company_disable_widget_ie": "0",
              "reply_by_sms_to_claim_email": "0",
              "google_analytics": "1",
              "querystring_enabled": "0",
              "company_plain_email_enabled": "0",
              "lead_name_play_enabled": "0",
              "is_attempts_to_exit_enabled": "0",
              "vodafone_requestid": "0",
              "country_multipliers": "1",
              "live_google_analytics": "1",
              "ilr_reports": "1",
              "double_layer_ivr": "1",
              "double_layer_ivr_delay": "5",
              "double_layer_ivr_ext": "2",
              "over_use_credits": "0",
              "thank_you_url_redirect": "0",
              "show_autopopup_once_enabled": "0",
              "send_sms_after_one_minutes_call_enabled": "0",
              "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
              "name_enable": "0",
              "fallback_number_enabled": "0",
              "fallback_number_country_code": "",
              "fallback_number": null,
              "fallback_exten": null,
              "fallback_delay": "0",
              "call_scheduled_canceled_setting": "0",
              "modified": "2019-06-28 09:54:27",
              "updated": "2019-06-28 09:54:27",
              "salt": "322ce115245499ec9ee357e63a5e6860",
              "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
              "2fa": "0",
              "aging": "0",
              "password_modified": "2019-03-01 07:39:39",
              "password_modified_ip": null,
              "password_strength": null,
              "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
              "email_enable": "1",
              "outcome_tracking": "1",
              "scheduled_mail_daily_report": "0",
              "scheduled_email_report": "",
              "tracking_number": "0",
              "dont_show_widget_in_mobile": "1",
              "show_time_changes_schedule": "0",
              "company_outcomeweekly_summary": "1",
              "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
              "three_layer_ivr": "0",
              "three_layer_ivr_delay": "0",
              "three_layer_ivr_ext": "0",
              "automated_followup_call": "1",
              "api_forward_leademail": "1",
              "custom_widget_js_file": "0",
              "timeline_tracking": "0",
              "mime_type": "0"
            }
          }
        }
      ]
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072753",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559824520752",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559824520263",
      "googleanalatics": "1716396086.1559824590",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 13:54:22",
      "created": "2019-06-06 13:35:20",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "507",
      "company_id": "2299",
      "lead_id": "507",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072753",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559824520752",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "64a5be0933e93d2f8dc03e7b8b2f7fab",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/64a5be0933e93d2f8dc03e7b8b2f7fab",
      "parentcallsid": "44dece86eba06e029cc3e53b5ff03f2f",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "20",
      "last_response_time": "70",
      "missed": "1",
      "start_ringing_time": "2019-06-06 13:36:56",
      "end_ringing_time": "2019-06-06 13:37:53",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-06 13:37:55",
      "created": "2019-06-06 13:36:35",
      "leadcreated": "2019-06-06 13:36:35",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "20",
      "lead_call_started": "2019-06-06 13:36:35",
      "lead_dial_call_status": "lead-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "no",
      "lead_hangupInitiated": "yes",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "507",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072753",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559824520752",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 13:37:56",
      "starttime": "13:36:35",
      "endtime": "13:36:55",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 13:36:35",
      "created": "2019-06-06 13:36:35",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072753",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559824520752",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559824520263",
      "googleanalatics": "1716396086.1559824590",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 13:54:22",
      "created": "2019-06-06 13:35:20",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "506",
      "company_id": "2299",
      "lead_id": "506",
      "widget_id": "2004",
      "agent_id": "59120",
      "called_agent_id": "0",
      "vistors_id": "2170072751",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559801013325",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "55323617bf8e5b56e085a9395f1c1f03",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/55323617bf8e5b56e085a9395f1c1f03",
      "parentcallsid": "32f75445c23fd0d6419dab919bde4fc5",
      "dialcallstatus": "Connected",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "37",
      "last_response_time": "175",
      "missed": "1",
      "start_ringing_time": "2019-06-06 12:25:38",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-06 12:25:38",
      "created": "2019-06-06 12:22:17",
      "leadcreated": "2019-06-06 12:22:17",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "37",
      "lead_call_started": "2019-06-06 12:22:17",
      "lead_dial_call_status": "completed",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "506",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59120",
      "called_agent_id": "0",
      "vistors_id": "2170072751",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559801013325",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918427700338",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 12:25:38",
      "starttime": "12:22:17",
      "endtime": "12:22:54",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 12:22:17",
      "created": "2019-06-06 12:22:17",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59120",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Captek",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "91",
      "phone": "6239022292",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "0",
      "email_info": "0",
      "created": "2019-06-06 12:21:43",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-06-06 12:21:43",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072751",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559801013325",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15598185331055",
      "googleanalatics": "1378033694.1559801011",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 12:32:26",
      "created": "2019-06-06 11:55:33",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "505",
      "company_id": "2299",
      "lead_id": "505",
      "widget_id": "2004",
      "agent_id": "59052",
      "called_agent_id": "0",
      "vistors_id": "2170072751",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559801013325",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "e7cee051efdf47340de38fde8f9989f5",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/e7cee051efdf47340de38fde8f9989f5",
      "parentcallsid": "6321824fc16f82733f53e891058d2797",
      "dialcallstatus": "Connected",
      "dialcallduration": "220",
      "credit_used": "0",
      "response_time": "168",
      "last_response_time": "242",
      "missed": "1",
      "start_ringing_time": "2019-06-06 12:20:17",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-06 12:20:17",
      "created": "2019-06-06 12:13:39",
      "leadcreated": "2019-06-06 12:13:39",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "4180",
      "retry_strategy": "1",
      "retry_response_time": "168",
      "lead_call_started": "2019-06-06 12:13:39",
      "lead_dial_call_status": "completed",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "505",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59052",
      "called_agent_id": "0",
      "vistors_id": "2170072751",
      "visitor_url_id": "0",
      "stratege_id": "4180",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559801013325",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918427700338",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "1",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 12:20:18",
      "starttime": "12:13:39",
      "endtime": "12:16:27",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 12:13:39",
      "created": "2019-06-06 12:13:39",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [
        {
          "id": "180250",
          "company_id": "2299",
          "callsid": "6321824fc16f82733f53e891058d2797",
          "lead_id": "505",
          "widget_id": "2004",
          "stratege_id": "4180",
          "retry_strategy": "1",
          "response_time": "168",
          "start_time": "12:16:02",
          "end_time": "12:16:27",
          "created": "2019-06-06 12:16:02"
        }
      ],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072751",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559801013325",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15598185331055",
      "googleanalatics": "1378033694.1559801011",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 12:32:26",
      "created": "2019-06-06 11:55:33",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "504",
      "company_id": "2299",
      "lead_id": "504",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072751",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559801013325",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "48396afed77a789bddad5a4aa49b9b2b",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/48396afed77a789bddad5a4aa49b9b2b",
      "parentcallsid": "02b458f37949ed9636c5f4fa91e65691",
      "dialcallstatus": "Connected",
      "dialcallduration": "57",
      "credit_used": "0",
      "response_time": "45",
      "last_response_time": "78",
      "missed": "1",
      "start_ringing_time": "2019-06-06 12:11:59",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-06 12:11:59",
      "created": "2019-06-06 12:10:07",
      "leadcreated": "2019-06-06 12:10:07",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "45",
      "lead_call_started": "2019-06-06 12:10:07",
      "lead_dial_call_status": "completed",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "504",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072751",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559801013325",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918427700338",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 12:11:59",
      "starttime": "12:10:07",
      "endtime": "12:10:52",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 12:10:07",
      "created": "2019-06-06 12:10:07",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072751",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559801013325",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15598185331055",
      "googleanalatics": "1378033694.1559801011",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 12:32:26",
      "created": "2019-06-06 11:55:33",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "503",
      "company_id": "2299",
      "lead_id": "503",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072751",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559801013325",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "091017fc5d659c38dfa098765e10c573",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/091017fc5d659c38dfa098765e10c573",
      "parentcallsid": "b97449c8776ab4a383587d94c7aaa17b",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "169",
      "last_response_time": "82",
      "missed": "1",
      "start_ringing_time": "2019-06-06 11:58:35",
      "end_ringing_time": "2019-06-06 11:59:35",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-06 11:59:45",
      "created": "2019-06-06 11:55:45",
      "leadcreated": "2019-06-06 11:55:45",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "4180",
      "retry_strategy": "1",
      "retry_response_time": "169",
      "lead_call_started": "2019-06-06 11:55:45",
      "lead_dial_call_status": "lead-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "no",
      "lead_hangupInitiated": "yes",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "503",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072751",
      "visitor_url_id": "0",
      "stratege_id": "4180",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559801013325",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918427700338",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "1",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 11:59:45",
      "starttime": "11:55:45",
      "endtime": "11:58:34",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 11:55:45",
      "created": "2019-06-06 11:55:45",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [
        {
          "id": "180249",
          "company_id": "2299",
          "callsid": "b97449c8776ab4a383587d94c7aaa17b",
          "lead_id": "503",
          "widget_id": "2004",
          "stratege_id": "4180",
          "retry_strategy": "1",
          "response_time": "169",
          "start_time": "11:58:02",
          "end_time": "11:58:34",
          "created": "2019-06-06 11:58:02"
        }
      ],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072751",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559801013325",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15598185331055",
      "googleanalatics": "1378033694.1559801011",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 12:32:26",
      "created": "2019-06-06 11:55:33",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "502",
      "company_id": "2299",
      "lead_id": "502",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072750",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559817934894",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "67130669bcefbe079580fc2107f652c7",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/67130669bcefbe079580fc2107f652c7",
      "parentcallsid": "64eaccadf95cb8d223bc56522f9605d2",
      "dialcallstatus": "Connected",
      "dialcallduration": "103",
      "credit_used": "0",
      "response_time": "219",
      "last_response_time": "131",
      "missed": "1",
      "start_ringing_time": "2019-06-06 11:52:34",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-06 11:52:34",
      "created": "2019-06-06 11:46:54",
      "leadcreated": "2019-06-06 11:46:54",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "4180",
      "retry_strategy": "1",
      "retry_response_time": "219",
      "lead_call_started": "2019-06-06 11:46:54",
      "lead_dial_call_status": "lead-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "no",
      "lead_hangupInitiated": "yes",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "502",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072750",
      "visitor_url_id": "0",
      "stratege_id": "4180",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559817934894",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918427700338",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "1",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 11:52:35",
      "starttime": "11:46:54",
      "endtime": "11:50:33",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 11:46:54",
      "created": "2019-06-06 11:46:54",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [
        {
          "id": "180248",
          "company_id": "2299",
          "callsid": "64eaccadf95cb8d223bc56522f9605d2",
          "lead_id": "502",
          "widget_id": "2004",
          "stratege_id": "4180",
          "retry_strategy": "1",
          "response_time": "219",
          "start_time": "11:50:02",
          "end_time": "11:50:33",
          "created": "2019-06-06 11:50:02"
        }
      ],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072750",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559817934894",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559817934767",
      "googleanalatics": "1181898567.1559731130",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 11:46:56",
      "created": "2019-06-06 11:45:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "500",
      "company_id": "2299",
      "lead_id": "500",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072748",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559800029943",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "CAf23976ec38b0a34f177f8331f3b6a362",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-06 07:50:54",
      "created": "2019-06-06 07:50:33",
      "leadcreated": "2019-06-06 07:50:33",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-06 07:50:33",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "500",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072748",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559800029943",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "0",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 07:50:54",
      "starttime": "07:50:33",
      "endtime": "07:50:33",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 07:50:33",
      "created": "2019-06-06 07:50:33",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072748",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559800029943",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15598038073174",
      "googleanalatics": "1302232776.1559800028",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 07:50:34",
      "created": "2019-06-06 07:50:07",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "499",
      "company_id": "2299",
      "lead_id": "499",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072747",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559803283929",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "CA69af0186fcb61785968cf605c76ed982",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-06 07:42:04",
      "created": "2019-06-06 07:41:43",
      "leadcreated": "2019-06-06 07:41:43",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-06 07:41:43",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "499",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072747",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559803283929",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "0",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-06 07:42:04",
      "starttime": "07:41:43",
      "endtime": "07:41:43",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-06 07:41:43",
      "created": "2019-06-06 07:41:43",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072747",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559803283929",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15598032831895",
      "googleanalatics": "1512104751.1559282790",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-06 07:49:43",
      "created": "2019-06-06 07:41:23",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "498",
      "company_id": "2299",
      "lead_id": "498",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072744",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559738649867",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "CAb5c8d976996f210eb829b18f5a369114",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-05 14:39:24",
      "created": "2019-06-05 14:35:48",
      "leadcreated": "2019-06-05 14:35:48",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-05 14:35:48",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "498",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072744",
      "visitor_url_id": "0",
      "stratege_id": "4180",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559738649867",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "1",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-05 14:39:24",
      "starttime": "14:35:48",
      "endtime": "14:35:48",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-05 14:35:48",
      "created": "2019-06-05 14:35:48",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [
        {
          "id": "180247",
          "company_id": "2299",
          "callsid": "CA808b8a0ff0928ae3c408742f25173291",
          "lead_id": "498",
          "widget_id": "2004",
          "stratege_id": "4180",
          "retry_strategy": "1",
          "response_time": "0",
          "start_time": "14:39:02",
          "end_time": "14:39:02",
          "created": "2019-06-05 14:39:02"
        }
      ],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072744",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559738649867",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15597389932138",
      "googleanalatics": "1717151024.1559739003",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-05 14:35:50",
      "created": "2019-06-05 13:49:54",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "497",
      "company_id": "2299",
      "lead_id": "497",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072745",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559722026976",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "CA5d1cb64448d42a644522db46f9da26f4",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-05 14:39:02",
      "created": "2019-06-05 14:32:25",
      "leadcreated": "2019-06-05 14:32:25",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-05 14:32:25",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "497",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072745",
      "visitor_url_id": "0",
      "stratege_id": "4203",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559722026976",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "2",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-05 14:39:02",
      "starttime": "14:32:25",
      "endtime": "14:32:25",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-05 14:32:25",
      "created": "2019-06-05 14:32:25",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [
        {
          "id": "180245",
          "company_id": "2299",
          "callsid": "CA336837ab3225de6645f9e7edd626ddaf",
          "lead_id": "497",
          "widget_id": "2004",
          "stratege_id": "4180",
          "retry_strategy": "1",
          "response_time": "0",
          "start_time": "14:36:01",
          "end_time": "14:36:01",
          "created": "2019-06-05 14:36:01"
        },
        {
          "id": "180246",
          "company_id": "2299",
          "callsid": "CAaf904034414153cd1ccc4ec033594135",
          "lead_id": "497",
          "widget_id": "2004",
          "stratege_id": "4203",
          "retry_strategy": "2",
          "response_time": "0",
          "start_time": "14:38:02",
          "end_time": "14:38:02",
          "created": "2019-06-05 14:38:02"
        }
      ],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072745",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559722026976",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15597415332423",
      "googleanalatics": "1786608987.1559722025",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-05 14:33:46",
      "created": "2019-06-05 14:32:13",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "496",
      "company_id": "2299",
      "lead_id": "496",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072743",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559738649867",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "CAe8fa3b344b604dc96bc8be9a60b987d6",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "1",
      "response_time": "0",
      "last_response_time": "25",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-05 13:55:02",
      "created": "2019-06-05 13:48:43",
      "leadcreated": "2019-06-05 13:48:43",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-05 13:48:43",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "496",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072743",
      "visitor_url_id": "0",
      "stratege_id": "4203",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559738649867",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "2",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-05 13:55:02",
      "starttime": "13:48:43",
      "endtime": "13:48:43",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-05 13:48:43",
      "created": "2019-06-05 13:48:43",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [
        {
          "id": "180243",
          "company_id": "2299",
          "callsid": "CA382d30db208921bfc0824e284e61cf59",
          "lead_id": "496",
          "widget_id": "2004",
          "stratege_id": "4180",
          "retry_strategy": "1",
          "response_time": "0",
          "start_time": "13:52:01",
          "end_time": "13:52:01",
          "created": "2019-06-05 13:52:01"
        },
        {
          "id": "180244",
          "company_id": "2299",
          "callsid": "CA8e763c6d47c81180798cc6444d6cc779",
          "lead_id": "496",
          "widget_id": "2004",
          "stratege_id": "4203",
          "retry_strategy": "2",
          "response_time": "0",
          "start_time": "13:54:02",
          "end_time": "13:54:02",
          "created": "2019-06-05 13:54:02"
        }
      ],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072743",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559738649867",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15597389101704",
      "googleanalatics": "216423857.1559738921",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-05 13:49:48",
      "created": "2019-06-05 13:48:31",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "495",
      "company_id": "2299",
      "lead_id": "495",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072742",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559738649867",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "CA09a51ab02c39eeafe70e2d084ec0024c",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "1",
      "response_time": "246",
      "last_response_time": "28",
      "missed": "1",
      "start_ringing_time": "2019-06-05 13:50:31",
      "end_ringing_time": "2019-06-05 13:50:42",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-05 13:50:42",
      "created": "2019-06-05 13:46:23",
      "leadcreated": "2019-06-05 13:46:23",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "246",
      "lead_call_started": "2019-06-05 13:46:23",
      "lead_dial_call_status": "no-answer",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "495",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072742",
      "visitor_url_id": "0",
      "stratege_id": "4180",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559738649867",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "1",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-05 13:50:44",
      "starttime": "13:46:23",
      "endtime": "13:50:29",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-05 13:46:23",
      "created": "2019-06-05 13:46:23",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [
        {
          "id": "180242",
          "company_id": "2299",
          "callsid": "CA75cd65108afe4ed1befc087ec1d941e3",
          "lead_id": "495",
          "widget_id": "2004",
          "stratege_id": "4180",
          "retry_strategy": "1",
          "response_time": "246",
          "start_time": "13:50:02",
          "end_time": "13:50:29",
          "created": "2019-06-05 13:50:02"
        }
      ],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072742",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559738649867",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15597386492506",
      "googleanalatics": "1170777657.1559738661",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-05 13:46:25",
      "created": "2019-06-05 13:44:09",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "494",
      "company_id": "2299",
      "lead_id": "494",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072740",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559713974632",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "CA39f777abbb32c714c206cb2212033417",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-05 08:54:13",
      "created": "2019-06-05 08:39:39",
      "leadcreated": "2019-06-05 08:39:39",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-05 08:39:39",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "494",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072740",
      "visitor_url_id": "0",
      "stratege_id": "4203",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559713974632",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "2",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-05 08:54:13",
      "starttime": "08:39:39",
      "endtime": "08:39:39",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-05 08:39:39",
      "created": "2019-06-05 08:39:39",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [
        {
          "id": "180240",
          "company_id": "2299",
          "callsid": "CA8eb4ce954d1bd18a5e720cda4f5a8d43",
          "lead_id": "494",
          "widget_id": "2004",
          "stratege_id": "4180",
          "retry_strategy": "1",
          "response_time": "0",
          "start_time": "08:43:01",
          "end_time": "08:43:01",
          "created": "2019-06-05 08:43:01"
        },
        {
          "id": "180241",
          "company_id": "2299",
          "callsid": "CAbe258b63db92c0ba33c746875b26e380",
          "lead_id": "494",
          "widget_id": "2004",
          "stratege_id": "4203",
          "retry_strategy": "2",
          "response_time": "0",
          "start_time": "08:45:02",
          "end_time": "08:45:02",
          "created": "2019-06-05 08:45:02"
        }
      ],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072740",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559713974632",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "",
      "countrycode": "IN",
      "countryname": "IN",
      "timezone": "Europe/London",
      "timezone_name": "Europe London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15597199303091",
      "googleanalatics": "1057287262.1559282580",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-05 08:41:32",
      "created": "2019-06-05 08:38:08",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "493",
      "company_id": "2299",
      "lead_id": "493",
      "widget_id": "2004",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072736",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559639764709",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "48e6f07907032ab30c66232c5feeb4c5",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/48e6f07907032ab30c66232c5feeb4c5",
      "parentcallsid": "33924c7d39199dc3323f723a8551ce46",
      "dialcallstatus": "Connected",
      "dialcallduration": "37",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "2019-06-04 12:27:56",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-04 12:27:56",
      "created": "2019-06-04 12:26:38",
      "leadcreated": "2019-06-04 12:26:38",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "20",
      "lead_call_started": "2019-06-04 12:26:38",
      "lead_dial_call_status": "completed",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/riqretrytest.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "493",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072736",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559639764709",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 12:27:56",
      "starttime": "12:26:38",
      "endtime": "12:26:58",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 12:26:38",
      "created": "2019-06-04 12:26:38",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59053",
      "company_id": "2299",
      "call_order": "5",
      "first_name": "Pankaj Vodafone",
      "last_name": "",
      "user_name": "",
      "email": "pankaj@responseiq.com",
      "phone_country": "91",
      "phone": "8847216376",
      "password": "",
      "image": "",
      "positions": "Pankaj ",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "0",
      "email_info": "0",
      "created": "2019-04-08 08:46:10",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "999",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-04-08 08:46:10",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072736",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559639764709",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596471221347",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:39",
      "created": "2019-06-04 12:18:43",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "492",
      "company_id": "2299",
      "lead_id": "492",
      "widget_id": "2004",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559643365152",
      "outcome_tracking_digit_id": "2",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "8ecf0dc38cf3f1cc355d519d17400e14",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/8ecf0dc38cf3f1cc355d519d17400e14",
      "parentcallsid": "6002a6de3f9ed737dcd3f12390d7cd70",
      "dialcallstatus": "Connected",
      "dialcallduration": "26",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "2019-06-04 12:25:54",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-04 12:25:54",
      "created": "2019-06-04 12:24:56",
      "leadcreated": "2019-06-04 12:24:56",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "17",
      "lead_call_started": "2019-06-04 12:24:56",
      "lead_dial_call_status": "lead-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "no",
      "lead_hangupInitiated": "yes",
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "492",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559643365152",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 12:25:53",
      "starttime": "12:24:56",
      "endtime": "12:25:13",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 12:24:56",
      "created": "2019-06-04 12:24:56",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59053",
      "company_id": "2299",
      "call_order": "5",
      "first_name": "Pankaj Vodafone",
      "last_name": "",
      "user_name": "",
      "email": "pankaj@responseiq.com",
      "phone_country": "91",
      "phone": "8847216376",
      "password": "",
      "image": "",
      "positions": "Pankaj ",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "0",
      "email_info": "0",
      "created": "2019-04-08 08:46:10",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "999",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-04-08 08:46:10",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072714",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559643365152",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559393734756",
      "googleanalatics": "966093901.1559393746",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:17",
      "created": "2019-06-01 13:55:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": "2",
      "company_id": "2299",
      "widget_id": "2004",
      "digits": "2",
      "name": "Existing Booking",
      "is_deleted": "0",
      "created": "2019-03-11 09:36:28"
    }
  },
  {
    "Calllog": {
      "id": "491",
      "company_id": "2299",
      "lead_id": "491",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072736",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559639764709",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "9d2d07658e5600886cef3b293b84cfc8",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 12:42:01",
      "created": "2019-06-04 12:23:31",
      "leadcreated": "2019-06-04 12:23:31",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-04 12:23:31",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/riqretrytest.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "491",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072736",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559639764709",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 12:42:01",
      "starttime": "12:23:31",
      "endtime": "12:23:31",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 12:23:31",
      "created": "2019-06-04 12:23:31",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072736",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559639764709",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596471221347",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:39",
      "created": "2019-06-04 12:18:43",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "490",
      "company_id": "2299",
      "lead_id": "490",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559643365152",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "9cceb8bf1cce91ad0af6c96811f6ffde",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 12:23:06",
      "created": "2019-06-04 12:22:01",
      "leadcreated": "2019-06-04 12:22:01",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-04 12:22:01",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "490",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559643365152",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 12:37:02",
      "starttime": "12:22:01",
      "endtime": "12:22:01",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "1",
      "leadcreated": "2019-06-04 12:22:01",
      "created": "2019-06-04 12:22:01",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072714",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559643365152",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559393734756",
      "googleanalatics": "966093901.1559393746",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:17",
      "created": "2019-06-01 13:55:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "489",
      "company_id": "2299",
      "lead_id": "489",
      "widget_id": "2004",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559643365152",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "0c644b89fefef0f3ee6fde68b8b7e57b",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/0c644b89fefef0f3ee6fde68b8b7e57b",
      "parentcallsid": "a24490f98a45542c9c7ba592ca131416",
      "dialcallstatus": "Connected",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "2019-06-04 12:21:34",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 12:21:34",
      "created": "2019-06-04 12:19:53",
      "leadcreated": "2019-06-04 12:19:53",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "64",
      "lead_call_started": "2019-06-04 12:19:53",
      "lead_dial_call_status": "completed",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "489",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559643365152",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 12:21:34",
      "starttime": "12:19:53",
      "endtime": "12:20:57",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 12:19:53",
      "created": "2019-06-04 12:19:53",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59053",
      "company_id": "2299",
      "call_order": "5",
      "first_name": "Pankaj Vodafone",
      "last_name": "",
      "user_name": "",
      "email": "pankaj@responseiq.com",
      "phone_country": "91",
      "phone": "8847216376",
      "password": "",
      "image": "",
      "positions": "Pankaj ",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "0",
      "email_info": "0",
      "created": "2019-04-08 08:46:10",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "999",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-04-08 08:46:10",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072714",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559643365152",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559393734756",
      "googleanalatics": "966093901.1559393746",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:17",
      "created": "2019-06-01 13:55:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "488",
      "company_id": "2299",
      "lead_id": "488",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072736",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559639764709",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 12:37:02",
      "created": "2019-06-04 12:18:57",
      "leadcreated": "2019-06-04 12:18:57",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-04 12:18:57",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": null,
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/riqretrytest.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "488",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072736",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559639764709",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 12:37:02",
      "starttime": "12:18:57",
      "endtime": "12:18:57",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 12:18:57",
      "created": "2019-06-04 12:18:57",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072736",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559639764709",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596471221347",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:39",
      "created": "2019-06-04 12:18:43",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "484",
      "company_id": "2346",
      "lead_id": "484",
      "widget_id": "2048",
      "agent_id": "59119",
      "called_agent_id": "0",
      "vistors_id": "2170072734",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559645159442",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/testonbeta.html",
      "querystring": "",
      "recordingsid": "85dde654b48fbe42dcc230f17a3af315",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/85dde654b48fbe42dcc230f17a3af315",
      "parentcallsid": "7e6c440452d90f59f4fa9e33bbfa5ac4",
      "dialcallstatus": "Connected",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "19",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "2019-06-04 11:47:44",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 11:47:44",
      "created": "2019-06-04 11:46:39",
      "leadcreated": "2019-06-04 11:46:39",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "19",
      "lead_call_started": "2019-06-04 11:46:39",
      "lead_dial_call_status": "lead-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "no",
      "lead_hangupInitiated": "yes",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/testonbeta.html"
    },
    "Company": {
      "id": "2346",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": null,
      "last_name": null,
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": null,
      "company_phone_country": "0",
      "company_extention": null,
      "company_delay": "0",
      "title": "testonbeta",
      "website": "http://oditiwebs.com/testonbeta.html",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "0",
      "master_company_id": "2299",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-06-04 11:17:04",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "gbp",
      "company_active_status": "1",
      "send_thanks_mail": "0",
      "step": "0",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "0",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "1",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": null,
      "company_weekly_summary_emails": null,
      "company_monthly_summary_emails": null,
      "company_daily_csv": "0",
      "company_daily_csv_emails": null,
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "0",
      "double_layer_ivr_delay": "0",
      "double_layer_ivr_ext": "0",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": null,
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-06 11:43:43",
      "updated": "2019-06-20 19:20:45",
      "salt": null,
      "secret": null,
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-06-04 10:17:04",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": null,
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "0",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "0",
      "company_outcomeweekly_summary_emails": null,
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "0",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "484",
      "company_id": "2346",
      "widget_id": "2048",
      "referrer_id": "0",
      "agent_id": "59119",
      "called_agent_id": "0",
      "vistors_id": "2170072734",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559645159442",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919569435788",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 11:47:45",
      "starttime": "11:46:39",
      "endtime": "11:46:58",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 11:46:39",
      "created": "2019-06-04 11:46:39",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2346",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": null,
        "last_name": null,
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": null,
        "company_phone_country": "0",
        "company_extention": null,
        "company_delay": "0",
        "title": "testonbeta",
        "website": "http://oditiwebs.com/testonbeta.html",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "0",
        "master_company_id": "2299",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-06-04 11:17:04",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "gbp",
        "company_active_status": "1",
        "send_thanks_mail": "0",
        "step": "0",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "0",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "1",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": null,
        "company_weekly_summary_emails": null,
        "company_monthly_summary_emails": null,
        "company_daily_csv": "0",
        "company_daily_csv_emails": null,
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "0",
        "double_layer_ivr_delay": "0",
        "double_layer_ivr_ext": "0",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": null,
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-06 11:43:43",
        "updated": "2019-06-20 19:20:45",
        "salt": null,
        "secret": null,
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-06-04 10:17:04",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": null,
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "0",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "0",
        "company_outcomeweekly_summary_emails": null,
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "0",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2048",
        "company_id": "2346",
        "widget_token": "NP37410351047VMC4333",
        "apikey": "RIQ27879472048",
        "title": "testonbeta",
        "widget_url": "http://oditiwebs.com/testonbeta.html",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-06-04 11:18:24",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-06-04 11:17:04",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "0",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "0",
        "visitor_duplicate_call_minutes": "5",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "1",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2048",
      "company_id": "2346",
      "widget_token": "NP37410351047VMC4333",
      "apikey": "RIQ27879472048",
      "title": "testonbeta",
      "widget_url": "http://oditiwebs.com/testonbeta.html",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-06-04 11:18:24",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-06-04 11:17:04",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "0",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "0",
      "visitor_duplicate_call_minutes": "5",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "1",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072734",
      "widget_id": "2048",
      "company_id": "2346",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559645159442",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "Mumbai",
      "device": "Desktop",
      "region": "Maharashtra",
      "countrycode": "IN",
      "countryname": "India",
      "timezone": "Asia/Kolkata",
      "timezone_name": "Kolkata",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596451594044",
      "googleanalatics": "",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/testonbeta.html",
      "querystring": "",
      "updated": "2019-06-04 11:46:41",
      "created": "2019-06-04 11:46:09",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "483",
      "company_id": "2346",
      "lead_id": "483",
      "widget_id": "2048",
      "agent_id": "59117",
      "called_agent_id": "0",
      "vistors_id": "2170072731",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559635877740",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/testonbeta.html",
      "querystring": "",
      "recordingsid": "1400d9044931a078fceee5b93da93aef",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/1400d9044931a078fceee5b93da93aef",
      "parentcallsid": "e75d258b219991e559bae8dea86c808f",
      "dialcallstatus": "Connected",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "17",
      "last_response_time": "94",
      "missed": "1",
      "start_ringing_time": "2019-06-04 11:42:59",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 11:42:59",
      "created": "2019-06-04 11:41:13",
      "leadcreated": "2019-06-04 11:41:13",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "17",
      "lead_call_started": "2019-06-04 11:41:13",
      "lead_dial_call_status": "agent-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2346",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": null,
      "last_name": null,
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": null,
      "company_phone_country": "0",
      "company_extention": null,
      "company_delay": "0",
      "title": "testonbeta",
      "website": "http://oditiwebs.com/testonbeta.html",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "0",
      "master_company_id": "2299",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-06-04 11:17:04",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "gbp",
      "company_active_status": "1",
      "send_thanks_mail": "0",
      "step": "0",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "0",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "1",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": null,
      "company_weekly_summary_emails": null,
      "company_monthly_summary_emails": null,
      "company_daily_csv": "0",
      "company_daily_csv_emails": null,
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "0",
      "double_layer_ivr_delay": "0",
      "double_layer_ivr_ext": "0",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": null,
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-06 11:43:43",
      "updated": "2019-06-20 19:20:45",
      "salt": null,
      "secret": null,
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-06-04 10:17:04",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": null,
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "0",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "0",
      "company_outcomeweekly_summary_emails": null,
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "0",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "483",
      "company_id": "2346",
      "widget_id": "2048",
      "referrer_id": "0",
      "agent_id": "59117",
      "called_agent_id": "0",
      "vistors_id": "2170072731",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559635877740",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918427700338",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 11:42:59",
      "starttime": "11:41:13",
      "endtime": "11:41:30",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 11:41:13",
      "created": "2019-06-04 11:41:13",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2346",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": null,
        "last_name": null,
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": null,
        "company_phone_country": "0",
        "company_extention": null,
        "company_delay": "0",
        "title": "testonbeta",
        "website": "http://oditiwebs.com/testonbeta.html",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "0",
        "master_company_id": "2299",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-06-04 11:17:04",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "gbp",
        "company_active_status": "1",
        "send_thanks_mail": "0",
        "step": "0",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "0",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "1",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": null,
        "company_weekly_summary_emails": null,
        "company_monthly_summary_emails": null,
        "company_daily_csv": "0",
        "company_daily_csv_emails": null,
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "0",
        "double_layer_ivr_delay": "0",
        "double_layer_ivr_ext": "0",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": null,
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-06 11:43:43",
        "updated": "2019-06-20 19:20:45",
        "salt": null,
        "secret": null,
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-06-04 10:17:04",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": null,
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "0",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "0",
        "company_outcomeweekly_summary_emails": null,
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "0",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2048",
        "company_id": "2346",
        "widget_token": "NP37410351047VMC4333",
        "apikey": "RIQ27879472048",
        "title": "testonbeta",
        "widget_url": "http://oditiwebs.com/testonbeta.html",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-06-04 11:18:24",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-06-04 11:17:04",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "0",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "0",
        "visitor_duplicate_call_minutes": "5",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "1",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59117",
      "company_id": "2346",
      "call_order": "2",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "91",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-06-04 11:17:05",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "",
      "access_level": "0",
      "last_login": "0000-00-00 00:00:00",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2346",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": null,
        "last_name": null,
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": null,
        "company_phone_country": "0",
        "company_extention": null,
        "company_delay": "0",
        "title": "testonbeta",
        "website": "http://oditiwebs.com/testonbeta.html",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "0",
        "master_company_id": "2299",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-06-04 11:17:04",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "gbp",
        "company_active_status": "1",
        "send_thanks_mail": "0",
        "step": "0",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "0",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "1",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": null,
        "company_weekly_summary_emails": null,
        "company_monthly_summary_emails": null,
        "company_daily_csv": "0",
        "company_daily_csv_emails": null,
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "0",
        "double_layer_ivr_delay": "0",
        "double_layer_ivr_ext": "0",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": null,
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-06 11:43:43",
        "updated": "2019-06-20 19:20:45",
        "salt": null,
        "secret": null,
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-06-04 10:17:04",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": null,
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "0",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "0",
        "company_outcomeweekly_summary_emails": null,
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "0",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2048",
      "company_id": "2346",
      "widget_token": "NP37410351047VMC4333",
      "apikey": "RIQ27879472048",
      "title": "testonbeta",
      "widget_url": "http://oditiwebs.com/testonbeta.html",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-06-04 11:18:24",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-06-04 11:17:04",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "0",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "0",
      "visitor_duplicate_call_minutes": "5",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "1",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072731",
      "widget_id": "2048",
      "company_id": "2346",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559635877740",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "Mumbai",
      "device": "Desktop",
      "region": "Maharashtra",
      "countrycode": "IN",
      "countryname": "India",
      "timezone": "Asia/Kolkata",
      "timezone_name": "Kolkata",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596431782326",
      "googleanalatics": "",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/testonbeta.html",
      "querystring": "",
      "updated": "2019-06-04 11:43:15",
      "created": "2019-06-04 11:18:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "482",
      "company_id": "2346",
      "lead_id": "482",
      "widget_id": "2048",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072731",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559635877740",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/testonbeta.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "f6a1774f55c6a3b50c18b2c1cec8c6a6",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 11:47:02",
      "created": "2019-06-04 11:28:35",
      "leadcreated": "2019-06-04 11:28:35",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-04 11:28:35",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": "",
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2346",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": null,
      "last_name": null,
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": null,
      "company_phone_country": "0",
      "company_extention": null,
      "company_delay": "0",
      "title": "testonbeta",
      "website": "http://oditiwebs.com/testonbeta.html",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "0",
      "master_company_id": "2299",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-06-04 11:17:04",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "gbp",
      "company_active_status": "1",
      "send_thanks_mail": "0",
      "step": "0",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "0",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "1",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": null,
      "company_weekly_summary_emails": null,
      "company_monthly_summary_emails": null,
      "company_daily_csv": "0",
      "company_daily_csv_emails": null,
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "0",
      "double_layer_ivr_delay": "0",
      "double_layer_ivr_ext": "0",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": null,
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-06 11:43:43",
      "updated": "2019-06-20 19:20:45",
      "salt": null,
      "secret": null,
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-06-04 10:17:04",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": null,
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "0",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "0",
      "company_outcomeweekly_summary_emails": null,
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "0",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "482",
      "company_id": "2346",
      "widget_id": "2048",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072731",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559635877740",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+917355828310",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 11:47:02",
      "starttime": "11:28:35",
      "endtime": "11:28:35",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 11:28:35",
      "created": "2019-06-04 11:28:35",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2346",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": null,
        "last_name": null,
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": null,
        "company_phone_country": "0",
        "company_extention": null,
        "company_delay": "0",
        "title": "testonbeta",
        "website": "http://oditiwebs.com/testonbeta.html",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "0",
        "master_company_id": "2299",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-06-04 11:17:04",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "gbp",
        "company_active_status": "1",
        "send_thanks_mail": "0",
        "step": "0",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "0",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "1",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": null,
        "company_weekly_summary_emails": null,
        "company_monthly_summary_emails": null,
        "company_daily_csv": "0",
        "company_daily_csv_emails": null,
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "0",
        "double_layer_ivr_delay": "0",
        "double_layer_ivr_ext": "0",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": null,
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-06 11:43:43",
        "updated": "2019-06-20 19:20:45",
        "salt": null,
        "secret": null,
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-06-04 10:17:04",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": null,
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "0",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "0",
        "company_outcomeweekly_summary_emails": null,
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "0",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2048",
        "company_id": "2346",
        "widget_token": "NP37410351047VMC4333",
        "apikey": "RIQ27879472048",
        "title": "testonbeta",
        "widget_url": "http://oditiwebs.com/testonbeta.html",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-06-04 11:18:24",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-06-04 11:17:04",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "0",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "0",
        "visitor_duplicate_call_minutes": "5",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "1",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2048",
      "company_id": "2346",
      "widget_token": "NP37410351047VMC4333",
      "apikey": "RIQ27879472048",
      "title": "testonbeta",
      "widget_url": "http://oditiwebs.com/testonbeta.html",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-06-04 11:18:24",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-06-04 11:17:04",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "0",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "0",
      "visitor_duplicate_call_minutes": "5",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "1",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072731",
      "widget_id": "2048",
      "company_id": "2346",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559635877740",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "Mumbai",
      "device": "Desktop",
      "region": "Maharashtra",
      "countrycode": "IN",
      "countryname": "India",
      "timezone": "Asia/Kolkata",
      "timezone_name": "Kolkata",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596431782326",
      "googleanalatics": "",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/testonbeta.html",
      "querystring": "",
      "updated": "2019-06-04 11:43:15",
      "created": "2019-06-04 11:18:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "481",
      "company_id": "2299",
      "lead_id": "481",
      "widget_id": "2004",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559643365152",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "8e0ef4a401ef08888bbc949e5bac2296",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/8e0ef4a401ef08888bbc949e5bac2296",
      "parentcallsid": "a9a10877ae074686f573e4ac4cdd70c3",
      "dialcallstatus": "Connected",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "2019-06-04 11:20:46",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 11:20:46",
      "created": "2019-06-04 11:16:21",
      "leadcreated": "2019-06-04 11:16:21",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "17",
      "lead_call_started": "2019-06-04 11:16:21",
      "lead_dial_call_status": "agent-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "481",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559643365152",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 11:20:46",
      "starttime": "11:16:21",
      "endtime": "11:16:38",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 11:16:21",
      "created": "2019-06-04 11:16:21",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59053",
      "company_id": "2299",
      "call_order": "5",
      "first_name": "Pankaj Vodafone",
      "last_name": "",
      "user_name": "",
      "email": "pankaj@responseiq.com",
      "phone_country": "91",
      "phone": "8847216376",
      "password": "",
      "image": "",
      "positions": "Pankaj ",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "0",
      "email_info": "0",
      "created": "2019-04-08 08:46:10",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "999",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-04-08 08:46:10",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072714",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559643365152",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559393734756",
      "googleanalatics": "966093901.1559393746",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:17",
      "created": "2019-06-01 13:55:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "479",
      "company_id": "2299",
      "lead_id": "479",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072730",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559635877740",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "576fc75f32ec42aae206e822ca191a74",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 11:13:41",
      "created": "2019-06-04 11:13:15",
      "leadcreated": "2019-06-04 11:13:15",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-04 11:13:15",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": "",
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "479",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072730",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559635877740",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+917355828310",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 11:26:02",
      "starttime": "11:13:15",
      "endtime": "11:13:15",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "1",
      "leadcreated": "2019-06-04 11:13:15",
      "created": "2019-06-04 11:13:15",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072730",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559635877740",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596431782326",
      "googleanalatics": "179483555.1559635875",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 11:13:16",
      "created": "2019-06-04 11:12:58",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "478",
      "company_id": "2299",
      "lead_id": "478",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072729",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559639764709",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "b323d46ef96dfc4f1766e410b16c2995",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 11:26:02",
      "created": "2019-06-04 11:07:31",
      "leadcreated": "2019-06-04 11:07:31",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-04 11:07:31",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/riqretrytest.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "478",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072729",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559639764709",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 11:26:02",
      "starttime": "11:07:31",
      "endtime": "11:07:31",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 11:07:31",
      "created": "2019-06-04 11:07:31",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072729",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559639764709",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596426482714",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 11:14:11",
      "created": "2019-06-04 11:04:08",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "477",
      "company_id": "2299",
      "lead_id": "477",
      "widget_id": "2004",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559635407144",
      "outcome_tracking_digit_id": "2",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "e216796e3da5057c54eeb6b4bea85faf",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/e216796e3da5057c54eeb6b4bea85faf",
      "parentcallsid": "769efdb8bd3e9e023cfd4c01d5e2da6e",
      "dialcallstatus": "Connected",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "2019-06-04 09:07:06",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 09:07:29",
      "created": "2019-06-04 09:03:47",
      "leadcreated": "2019-06-04 09:03:47",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "21",
      "lead_call_started": "2019-06-04 09:03:47",
      "lead_dial_call_status": "completed",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "477",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559635407144",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 09:07:06",
      "starttime": "09:03:47",
      "endtime": "09:04:08",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 09:03:47",
      "created": "2019-06-04 09:03:47",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59053",
      "company_id": "2299",
      "call_order": "5",
      "first_name": "Pankaj Vodafone",
      "last_name": "",
      "user_name": "",
      "email": "pankaj@responseiq.com",
      "phone_country": "91",
      "phone": "8847216376",
      "password": "",
      "image": "",
      "positions": "Pankaj ",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "0",
      "email_info": "0",
      "created": "2019-04-08 08:46:10",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "999",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-04-08 08:46:10",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072714",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559643365152",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559393734756",
      "googleanalatics": "966093901.1559393746",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:17",
      "created": "2019-06-01 13:55:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": "2",
      "company_id": "2299",
      "widget_id": "2004",
      "digits": "2",
      "name": "Existing Booking",
      "is_deleted": "0",
      "created": "2019-03-11 09:36:28"
    }
  },
  {
    "Calllog": {
      "id": "476",
      "company_id": "2299",
      "lead_id": "476",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072726",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634366108",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "65b07ee638b9868342a7d67c52034662",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 09:03:21",
      "created": "2019-06-04 09:02:25",
      "leadcreated": "2019-06-04 09:02:25",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-04 09:02:25",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "476",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072726",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634366108",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 09:12:03",
      "starttime": "09:02:25",
      "endtime": "09:02:25",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "1",
      "leadcreated": "2019-06-04 09:02:25",
      "created": "2019-06-04 09:02:25",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072726",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559634366108",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596343663630",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 09:03:12",
      "created": "2019-06-04 08:46:06",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "475",
      "company_id": "2299",
      "lead_id": "475",
      "widget_id": "2004",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072726",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634366108",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "4810a750bfd24894080fe599e648f489",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/4810a750bfd24894080fe599e648f489",
      "parentcallsid": "c0e9cd317b403993889e71c13019da6d",
      "dialcallstatus": "Connected",
      "dialcallduration": "40",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "2019-06-04 09:00:52",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-04 09:00:52",
      "created": "2019-06-04 08:59:22",
      "leadcreated": "2019-06-04 08:59:22",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "39",
      "lead_call_started": "2019-06-04 08:59:22",
      "lead_dial_call_status": "lead-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "no",
      "lead_hangupInitiated": "yes",
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "475",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072726",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634366108",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 09:00:52",
      "starttime": "08:59:22",
      "endtime": "09:00:01",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 08:59:22",
      "created": "2019-06-04 08:59:22",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59053",
      "company_id": "2299",
      "call_order": "5",
      "first_name": "Pankaj Vodafone",
      "last_name": "",
      "user_name": "",
      "email": "pankaj@responseiq.com",
      "phone_country": "91",
      "phone": "8847216376",
      "password": "",
      "image": "",
      "positions": "Pankaj ",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "0",
      "email_info": "0",
      "created": "2019-04-08 08:46:10",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "999",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-04-08 08:46:10",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072726",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559634366108",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596343663630",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 09:03:12",
      "created": "2019-06-04 08:46:06",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "474",
      "company_id": "2299",
      "lead_id": "474",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634512633",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "31ef99bc0e303b690053a69c6fa322e4",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-04 09:12:02",
      "created": "2019-06-04 08:58:20",
      "leadcreated": "2019-06-04 08:58:20",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-04 08:58:20",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "474",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634512633",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 09:12:02",
      "starttime": "08:58:20",
      "endtime": "08:58:20",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 08:58:20",
      "created": "2019-06-04 08:58:20",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072714",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559643365152",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559393734756",
      "googleanalatics": "966093901.1559393746",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:17",
      "created": "2019-06-01 13:55:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "473",
      "company_id": "2299",
      "lead_id": "473",
      "widget_id": "2004",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634512633",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "1b24d926cce8520bbfe92ba343e998b4",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/1b24d926cce8520bbfe92ba343e998b4",
      "parentcallsid": "61a5d94498785d98e0c7d53c24c2649a",
      "dialcallstatus": "Connected",
      "dialcallduration": "42",
      "credit_used": "0",
      "response_time": "12",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "2019-06-04 08:50:08",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-04 08:50:08",
      "created": "2019-06-04 08:49:03",
      "leadcreated": "2019-06-04 08:49:03",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "12",
      "lead_call_started": "2019-06-04 08:49:03",
      "lead_dial_call_status": "lead-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "no",
      "lead_hangupInitiated": "yes",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "473",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634512633",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 08:50:09",
      "starttime": "08:49:03",
      "endtime": "08:49:15",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 08:49:03",
      "created": "2019-06-04 08:49:03",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59053",
      "company_id": "2299",
      "call_order": "5",
      "first_name": "Pankaj Vodafone",
      "last_name": "",
      "user_name": "",
      "email": "pankaj@responseiq.com",
      "phone_country": "91",
      "phone": "8847216376",
      "password": "",
      "image": "",
      "positions": "Pankaj ",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "0",
      "email_info": "0",
      "created": "2019-04-08 08:46:10",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "999",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-04-08 08:46:10",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072714",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559643365152",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559393734756",
      "googleanalatics": "966093901.1559393746",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:17",
      "created": "2019-06-01 13:55:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "472",
      "company_id": "2299",
      "lead_id": "472",
      "widget_id": "2004",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072726",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634366108",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "7785bde9056121d3057094c63af68afe",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/7785bde9056121d3057094c63af68afe",
      "parentcallsid": "53ec3cdb19014dd5f8094bedbc385de6",
      "dialcallstatus": "Connected",
      "dialcallduration": "28",
      "credit_used": "0",
      "response_time": "12",
      "last_response_time": "42",
      "missed": "1",
      "start_ringing_time": "2019-06-04 08:47:33",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-04 08:47:33",
      "created": "2019-06-04 08:46:43",
      "leadcreated": "2019-06-04 08:46:43",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "12",
      "lead_call_started": "2019-06-04 08:46:43",
      "lead_dial_call_status": "lead-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "no",
      "lead_hangupInitiated": "yes",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "472",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59053",
      "called_agent_id": "0",
      "vistors_id": "2170072726",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559634366108",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-04 08:47:33",
      "starttime": "08:46:43",
      "endtime": "08:46:55",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-04 08:46:43",
      "created": "2019-06-04 08:46:43",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59053",
      "company_id": "2299",
      "call_order": "5",
      "first_name": "Pankaj Vodafone",
      "last_name": "",
      "user_name": "",
      "email": "pankaj@responseiq.com",
      "phone_country": "91",
      "phone": "8847216376",
      "password": "",
      "image": "",
      "positions": "Pankaj ",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "0",
      "email_info": "0",
      "created": "2019-04-08 08:46:10",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "999",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-04-08 08:46:10",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072726",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559634366108",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15596343663630",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 09:03:12",
      "created": "2019-06-04 08:46:06",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "471",
      "company_id": "2299",
      "lead_id": "471",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072724",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559565588382",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "8a1808d1b1c4d99b8fcff259ffe248f6",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/8a1808d1b1c4d99b8fcff259ffe248f6",
      "parentcallsid": "dce241412da777eb0068cfa07c8cc2a2",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "21",
      "last_response_time": "15",
      "missed": "1",
      "start_ringing_time": "2019-06-03 13:40:38",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-03 13:40:47",
      "created": "2019-06-03 13:40:16",
      "leadcreated": "2019-06-03 13:40:16",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "21",
      "lead_call_started": "2019-06-03 13:40:16",
      "lead_dial_call_status": "agent-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "471",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072724",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559565588382",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+917837317983",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-03 13:40:48",
      "starttime": "13:40:16",
      "endtime": "13:40:37",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-03 13:40:16",
      "created": "2019-06-03 13:40:16",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072724",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559565588382",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15595655863499",
      "googleanalatics": "777316249.1559544390",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-03 13:40:18",
      "created": "2019-06-03 13:39:46",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "459",
      "company_id": "2299",
      "lead_id": "459",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072712",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559373246514",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/riqwidget.html.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "be3e1afa2c2d0403640155084f9ba143",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-02 08:13:01",
      "created": "2019-06-02 08:00:03",
      "leadcreated": "2019-06-01 09:42:57",
      "lead_type": "Scheduled",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-06-02 08:00:03",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": "",
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "2",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/riqwidget.html.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "459",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072712",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559373246514",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-02 08:13:02",
      "starttime": "08:00:03",
      "endtime": "08:00:03",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-01 09:42:57",
      "created": "2019-06-02 08:00:03",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": [
        {
          "id": "120876",
          "lead_id": "459",
          "company_id": "2299",
          "leadsource_id": "0",
          "widget_id": "2004",
          "agent_id": "0",
          "scheduledby_id": "2299",
          "schdeuled_date": "2019-06-02",
          "scheduled_time": "08:00:00",
          "scheduled_time_text": "08:00/8:00 am in the morning",
          "status": "1",
          "sms_sent_time": "0000-00-00 00:00:00",
          "sms_receive_time": "0000-00-00 00:00:00",
          "sent": "0",
          "follow_up_call": "0",
          "follow_up_call_mail": "0",
          "created": "2019-06-01 09:42:57",
          "Company": {
            "id": "2299",
            "cust": null,
            "cust_no": "0",
            "user_id": "0",
            "accents": "en-GB",
            "voice_type": "man",
            "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
            "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
            "twilio_account_status": "0",
            "plivo_accountsid": null,
            "plivo_auth_token": null,
            "plivo_account_status": "0",
            "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
            "api_type": "2",
            "carrier": "idt",
            "lead_carrier": "idt",
            "username": "",
            "name": "Pankaj Kumar",
            "first_name": "Pankaj",
            "last_name": "Kumar",
            "company_name": "ogs",
            "friendly_name": null,
            "date_created": null,
            "email": "pankaj@responseiq.com",
            "country_code": "44",
            "country_id": "225",
            "phone": "6463970198",
            "company_phone": "9646977254",
            "company_phone_country": "91",
            "company_extention": "1",
            "company_delay": "5",
            "title": "ogs",
            "website": "http://127.0.0.1:9999/test/widget",
            "password": "",
            "address_1": "",
            "zip_code": "",
            "logo": "",
            "call": "1",
            "country_of_business": "uk",
            "financial_email": "",
            "vat_number": null,
            "status": "1",
            "master_status": "1",
            "master_company_id": "0",
            "location_enable": "0",
            "department_enable": "0",
            "created": "2019-03-01 07:39:39",
            "is_trashed": "0",
            "is_type": "0",
            "selector": "Agent",
            "currency": "usd",
            "company_active_status": "1",
            "send_thanks_mail": "1",
            "step": "6",
            "manual_install": "0",
            "lead_revisit_alert": "0",
            "company_activation_reminder": "0",
            "became_customer": null,
            "three_call_missed": "0000-00-00 00:00:00",
            "agents_send_login_enabled": "0",
            "twilio_account_suspend": "1",
            "plivo_account_suspend": "1",
            "tc_confirmed": "1",
            "gdpr_enable": "0",
            "widget_country_code_edit": "1",
            "cancel_subscription_enable": "1",
            "api_enable": "1",
            "ilr_sms_module_enable": "0",
            "company_disable_widget": "0",
            "no_call_notify": "0",
            "company_daily_summary": "0",
            "company_weekly_summary": "0",
            "company_monthly_summary": "0",
            "company_daily_summary_emails": "pankaj@responseiq.com",
            "company_weekly_summary_emails": "pankaj@responseiq.com",
            "company_monthly_summary_emails": "pankaj@responseiq.com",
            "company_daily_csv": "0",
            "company_daily_csv_emails": "pankaj@responseiq.com",
            "company_disable_widget_ie": "0",
            "reply_by_sms_to_claim_email": "0",
            "google_analytics": "1",
            "querystring_enabled": "0",
            "company_plain_email_enabled": "0",
            "lead_name_play_enabled": "0",
            "is_attempts_to_exit_enabled": "0",
            "vodafone_requestid": "0",
            "country_multipliers": "1",
            "live_google_analytics": "1",
            "ilr_reports": "1",
            "double_layer_ivr": "1",
            "double_layer_ivr_delay": "5",
            "double_layer_ivr_ext": "2",
            "over_use_credits": "0",
            "thank_you_url_redirect": "0",
            "show_autopopup_once_enabled": "0",
            "send_sms_after_one_minutes_call_enabled": "0",
            "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
            "name_enable": "0",
            "fallback_number_enabled": "0",
            "fallback_number_country_code": "",
            "fallback_number": null,
            "fallback_exten": null,
            "fallback_delay": "0",
            "call_scheduled_canceled_setting": "0",
            "modified": "2019-06-28 09:54:27",
            "updated": "2019-06-28 09:54:27",
            "salt": "322ce115245499ec9ee357e63a5e6860",
            "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
            "2fa": "0",
            "aging": "0",
            "password_modified": "2019-03-01 07:39:39",
            "password_modified_ip": null,
            "password_strength": null,
            "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
            "email_enable": "1",
            "outcome_tracking": "1",
            "scheduled_mail_daily_report": "0",
            "scheduled_email_report": "",
            "tracking_number": "0",
            "dont_show_widget_in_mobile": "1",
            "show_time_changes_schedule": "0",
            "company_outcomeweekly_summary": "1",
            "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
            "three_layer_ivr": "0",
            "three_layer_ivr_delay": "0",
            "three_layer_ivr_ext": "0",
            "automated_followup_call": "1",
            "api_forward_leademail": "1",
            "custom_widget_js_file": "0",
            "timeline_tracking": "0",
            "mime_type": "0"
          },
          "Lead": {
            "id": "459",
            "company_id": "2299",
            "widget_id": "2004",
            "referrer_id": "0",
            "agent_id": "0",
            "called_agent_id": "0",
            "vistors_id": "2170072712",
            "visitor_url_id": "0",
            "stratege_id": "0",
            "leadsource_id": "0",
            "department_id": "0",
            "session_id": "1559373246514",
            "name": "",
            "username": "",
            "email": "",
            "fromemail": null,
            "phone_country": "91",
            "country_name": "IN",
            "phone": "+919646977254",
            "phone_type": "",
            "bookingno": null,
            "template_capture_number": "",
            "moveid": null,
            "requestid": null,
            "subject": "",
            "message": "",
            "contact_pref": "",
            "registration": "",
            "vehicle_details": "",
            "color": "",
            "price": "",
            "type": "0",
            "appointmentset": "0",
            "retry_strategy": "0",
            "location": "0",
            "missed": "1",
            "leadbody": "",
            "finalbody": "",
            "json_arr": "",
            "updated": "2019-06-02 08:13:02",
            "starttime": "08:00:03",
            "endtime": "08:00:03",
            "notify": "0",
            "notify_agent_id": "0",
            "no_click_notify": "0",
            "is_deleted": "0",
            "out_of_hours_status": "0",
            "revisit_checked": "0",
            "lead_revisits_counts": "0",
            "leadcallcompleted": "0",
            "leadcreated": "2019-06-01 09:42:57",
            "created": "2019-06-02 08:00:03",
            "sms_leads_logs_id": "0",
            "is_lead_type": "0",
            "full_name": null,
            "postcode": null,
            "country": null,
            "emailaddress": null,
            "question_first": null,
            "question_second": null,
            "question_third": null,
            "lead_reference_number": null,
            "distribution_ID": null,
            "lead_created_gmt": null,
            "Company": {
              "id": "2299",
              "cust": null,
              "cust_no": "0",
              "user_id": "0",
              "accents": "en-GB",
              "voice_type": "man",
              "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
              "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
              "twilio_account_status": "0",
              "plivo_accountsid": null,
              "plivo_auth_token": null,
              "plivo_account_status": "0",
              "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
              "api_type": "2",
              "carrier": "idt",
              "lead_carrier": "idt",
              "username": "",
              "name": "Pankaj Kumar",
              "first_name": "Pankaj",
              "last_name": "Kumar",
              "company_name": "ogs",
              "friendly_name": null,
              "date_created": null,
              "email": "pankaj@responseiq.com",
              "country_code": "44",
              "country_id": "225",
              "phone": "6463970198",
              "company_phone": "9646977254",
              "company_phone_country": "91",
              "company_extention": "1",
              "company_delay": "5",
              "title": "ogs",
              "website": "http://127.0.0.1:9999/test/widget",
              "password": "",
              "address_1": "",
              "zip_code": "",
              "logo": "",
              "call": "1",
              "country_of_business": "uk",
              "financial_email": "",
              "vat_number": null,
              "status": "1",
              "master_status": "1",
              "master_company_id": "0",
              "location_enable": "0",
              "department_enable": "0",
              "created": "2019-03-01 07:39:39",
              "is_trashed": "0",
              "is_type": "0",
              "selector": "Agent",
              "currency": "usd",
              "company_active_status": "1",
              "send_thanks_mail": "1",
              "step": "6",
              "manual_install": "0",
              "lead_revisit_alert": "0",
              "company_activation_reminder": "0",
              "became_customer": null,
              "three_call_missed": "0000-00-00 00:00:00",
              "agents_send_login_enabled": "0",
              "twilio_account_suspend": "1",
              "plivo_account_suspend": "1",
              "tc_confirmed": "1",
              "gdpr_enable": "0",
              "widget_country_code_edit": "1",
              "cancel_subscription_enable": "1",
              "api_enable": "1",
              "ilr_sms_module_enable": "0",
              "company_disable_widget": "0",
              "no_call_notify": "0",
              "company_daily_summary": "0",
              "company_weekly_summary": "0",
              "company_monthly_summary": "0",
              "company_daily_summary_emails": "pankaj@responseiq.com",
              "company_weekly_summary_emails": "pankaj@responseiq.com",
              "company_monthly_summary_emails": "pankaj@responseiq.com",
              "company_daily_csv": "0",
              "company_daily_csv_emails": "pankaj@responseiq.com",
              "company_disable_widget_ie": "0",
              "reply_by_sms_to_claim_email": "0",
              "google_analytics": "1",
              "querystring_enabled": "0",
              "company_plain_email_enabled": "0",
              "lead_name_play_enabled": "0",
              "is_attempts_to_exit_enabled": "0",
              "vodafone_requestid": "0",
              "country_multipliers": "1",
              "live_google_analytics": "1",
              "ilr_reports": "1",
              "double_layer_ivr": "1",
              "double_layer_ivr_delay": "5",
              "double_layer_ivr_ext": "2",
              "over_use_credits": "0",
              "thank_you_url_redirect": "0",
              "show_autopopup_once_enabled": "0",
              "send_sms_after_one_minutes_call_enabled": "0",
              "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
              "name_enable": "0",
              "fallback_number_enabled": "0",
              "fallback_number_country_code": "",
              "fallback_number": null,
              "fallback_exten": null,
              "fallback_delay": "0",
              "call_scheduled_canceled_setting": "0",
              "modified": "2019-06-28 09:54:27",
              "updated": "2019-06-28 09:54:27",
              "salt": "322ce115245499ec9ee357e63a5e6860",
              "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
              "2fa": "0",
              "aging": "0",
              "password_modified": "2019-03-01 07:39:39",
              "password_modified_ip": null,
              "password_strength": null,
              "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
              "email_enable": "1",
              "outcome_tracking": "1",
              "scheduled_mail_daily_report": "0",
              "scheduled_email_report": "",
              "tracking_number": "0",
              "dont_show_widget_in_mobile": "1",
              "show_time_changes_schedule": "0",
              "company_outcomeweekly_summary": "1",
              "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
              "three_layer_ivr": "0",
              "three_layer_ivr_delay": "0",
              "three_layer_ivr_ext": "0",
              "automated_followup_call": "1",
              "api_forward_leademail": "1",
              "custom_widget_js_file": "0",
              "timeline_tracking": "0",
              "mime_type": "0"
            },
            "Widget": {
              "id": "2004",
              "company_id": "2299",
              "widget_token": "9U3VLWE2R11P",
              "apikey": "RIQ130954042004",
              "title": "",
              "widget_url": "http://127.0.0.1:9999/test/widget",
              "logo": null,
              "port": "http://",
              "select_agent_or_team": "0",
              "status": "1",
              "install_status": "0",
              "installed_mail_sent": "1",
              "uninstall_mail_sent": "0",
              "installed_date": "2019-03-01 08:12:07",
              "visit_customize_status": "1",
              "agree_call_recording": "1",
              "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
              "welcometextschedule": null,
              "welcometext_animate_1": "0",
              "welcometextschedule_animate_2": "0",
              "widget_country_code_edit": "0",
              "created": "2019-03-01 07:40:37",
              "first_time_installation": "1",
              "call_schedule_button_mailsent": "1",
              "widgets_departments": "0",
              "visitor_time_call_enabled": "1",
              "visitor_duplicate_call_minutes": "1",
              "thank_you_url_redirect": "0",
              "thank_you_url": null,
              "webhook_url_port": "",
              "webhook_url": "",
              "webhook_events": "",
              "number_lookup": "2",
              "widget_file": "default"
            },
            "IqSource": [],
            "RetryResponseAttempt": [],
            "ScheduledCall": [
              {
                "id": "120876",
                "lead_id": "459",
                "company_id": "2299",
                "leadsource_id": "0",
                "widget_id": "2004",
                "agent_id": "0",
                "scheduledby_id": "2299",
                "schdeuled_date": "2019-06-02",
                "scheduled_time": "08:00:00",
                "scheduled_time_text": "08:00/8:00 am in the morning",
                "status": "1",
                "sms_sent_time": "0000-00-00 00:00:00",
                "sms_receive_time": "0000-00-00 00:00:00",
                "sent": "0",
                "follow_up_call": "0",
                "follow_up_call_mail": "0",
                "created": "2019-06-01 09:42:57"
              }
            ]
          },
          "Agent": []
        }
      ]
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": null,
      "widget_id": null,
      "company_id": null,
      "visitor_url_id": null,
      "referrer_id": null,
      "session_id": null,
      "ipaddress": null,
      "clicks": null,
      "browser": null,
      "city": null,
      "device": null,
      "region": null,
      "countrycode": null,
      "countryname": null,
      "timezone": null,
      "timezone_name": null,
      "tooltip_close": null,
      "tooltip_close_click": null,
      "saveattempts_to_exit": null,
      "browsersession": null,
      "googleanalatics": null,
      "user_agent": null,
      "visitor_url": null,
      "querystring": null,
      "updated": null,
      "created": null
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "463",
      "company_id": "2299",
      "lead_id": "463",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559393735295",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "ec97c104661c3aafd5b4d562d8e2cc0e",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/ec97c104661c3aafd5b4d562d8e2cc0e",
      "parentcallsid": "25e776496ea0c5e6a0d2398c43fdfd3b",
      "dialcallstatus": "Connected",
      "dialcallduration": "7",
      "credit_used": "0",
      "response_time": "17",
      "last_response_time": "24",
      "missed": "1",
      "start_ringing_time": "2019-06-01 13:56:23",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-01 13:56:23",
      "created": "2019-06-01 13:55:51",
      "leadcreated": "2019-06-01 13:55:51",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "17",
      "lead_call_started": "2019-06-01 13:55:51",
      "lead_dial_call_status": "completed",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "463",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072714",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559393735295",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-01 13:56:23",
      "starttime": "13:55:51",
      "endtime": "13:56:08",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-01 13:55:51",
      "created": "2019-06-01 13:55:51",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072714",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559643365152",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559393734756",
      "googleanalatics": "966093901.1559393746",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-04 12:26:17",
      "created": "2019-06-01 13:55:34",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "462",
      "company_id": "2299",
      "lead_id": "462",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072713",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559392213118",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "3a94ed29a03464d2d27a3194088e8c5a",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/3a94ed29a03464d2d27a3194088e8c5a",
      "parentcallsid": "fd5e50cf8374b23b562f432a268805b2",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "14",
      "last_response_time": "18",
      "missed": "1",
      "start_ringing_time": "2019-06-01 13:54:41",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-01 13:54:53",
      "created": "2019-06-01 13:54:26",
      "leadcreated": "2019-06-01 13:54:26",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "14",
      "lead_call_started": "2019-06-01 13:54:26",
      "lead_dial_call_status": "agent-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "462",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072713",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559392213118",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-01 13:54:53",
      "starttime": "13:54:26",
      "endtime": "13:54:40",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-01 13:54:26",
      "created": "2019-06-01 13:54:26",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072713",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559392213118",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15593922121629",
      "googleanalatics": "84710726.1559392223",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-01 13:54:27",
      "created": "2019-06-01 13:30:12",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "461",
      "company_id": "2299",
      "lead_id": "461",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "59015",
      "vistors_id": "2170072713",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": null,
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "019725a1e024a942b16edc13466cd8f4",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/019725a1e024a942b16edc13466cd8f4",
      "parentcallsid": "31e744ddb0d159b1c251446b078d3361",
      "dialcallstatus": "Connected",
      "dialcallduration": "15",
      "credit_used": "0",
      "response_time": "22",
      "last_response_time": "28",
      "missed": "1",
      "start_ringing_time": "2019-06-01 13:46:45",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-06-01 13:46:45",
      "created": "2019-06-01 13:46:01",
      "leadcreated": "2019-06-01 13:39:55",
      "lead_type": "Scheduled",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "22",
      "lead_call_started": "2019-06-01 13:46:01",
      "lead_dial_call_status": "completed",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": null,
      "riq_current_url": null
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "461",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "59015",
      "vistors_id": "2170072713",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": null,
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-01 13:46:45",
      "starttime": "13:46:01",
      "endtime": "13:46:23",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-01 13:39:55",
      "created": "2019-06-01 13:46:01",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": [
        {
          "id": "120877",
          "lead_id": "461",
          "company_id": "2299",
          "leadsource_id": "0",
          "widget_id": "2004",
          "agent_id": "59015",
          "scheduledby_id": "2299",
          "schdeuled_date": "2019-06-01",
          "scheduled_time": "13:45:00",
          "scheduled_time_text": null,
          "status": "1",
          "sms_sent_time": "0000-00-00 00:00:00",
          "sms_receive_time": "0000-00-00 00:00:00",
          "sent": "0",
          "follow_up_call": "1",
          "follow_up_call_mail": "0",
          "created": "2019-06-01 13:39:55",
          "Company": {
            "id": "2299",
            "cust": null,
            "cust_no": "0",
            "user_id": "0",
            "accents": "en-GB",
            "voice_type": "man",
            "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
            "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
            "twilio_account_status": "0",
            "plivo_accountsid": null,
            "plivo_auth_token": null,
            "plivo_account_status": "0",
            "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
            "api_type": "2",
            "carrier": "idt",
            "lead_carrier": "idt",
            "username": "",
            "name": "Pankaj Kumar",
            "first_name": "Pankaj",
            "last_name": "Kumar",
            "company_name": "ogs",
            "friendly_name": null,
            "date_created": null,
            "email": "pankaj@responseiq.com",
            "country_code": "44",
            "country_id": "225",
            "phone": "6463970198",
            "company_phone": "9646977254",
            "company_phone_country": "91",
            "company_extention": "1",
            "company_delay": "5",
            "title": "ogs",
            "website": "http://127.0.0.1:9999/test/widget",
            "password": "",
            "address_1": "",
            "zip_code": "",
            "logo": "",
            "call": "1",
            "country_of_business": "uk",
            "financial_email": "",
            "vat_number": null,
            "status": "1",
            "master_status": "1",
            "master_company_id": "0",
            "location_enable": "0",
            "department_enable": "0",
            "created": "2019-03-01 07:39:39",
            "is_trashed": "0",
            "is_type": "0",
            "selector": "Agent",
            "currency": "usd",
            "company_active_status": "1",
            "send_thanks_mail": "1",
            "step": "6",
            "manual_install": "0",
            "lead_revisit_alert": "0",
            "company_activation_reminder": "0",
            "became_customer": null,
            "three_call_missed": "0000-00-00 00:00:00",
            "agents_send_login_enabled": "0",
            "twilio_account_suspend": "1",
            "plivo_account_suspend": "1",
            "tc_confirmed": "1",
            "gdpr_enable": "0",
            "widget_country_code_edit": "1",
            "cancel_subscription_enable": "1",
            "api_enable": "1",
            "ilr_sms_module_enable": "0",
            "company_disable_widget": "0",
            "no_call_notify": "0",
            "company_daily_summary": "0",
            "company_weekly_summary": "0",
            "company_monthly_summary": "0",
            "company_daily_summary_emails": "pankaj@responseiq.com",
            "company_weekly_summary_emails": "pankaj@responseiq.com",
            "company_monthly_summary_emails": "pankaj@responseiq.com",
            "company_daily_csv": "0",
            "company_daily_csv_emails": "pankaj@responseiq.com",
            "company_disable_widget_ie": "0",
            "reply_by_sms_to_claim_email": "0",
            "google_analytics": "1",
            "querystring_enabled": "0",
            "company_plain_email_enabled": "0",
            "lead_name_play_enabled": "0",
            "is_attempts_to_exit_enabled": "0",
            "vodafone_requestid": "0",
            "country_multipliers": "1",
            "live_google_analytics": "1",
            "ilr_reports": "1",
            "double_layer_ivr": "1",
            "double_layer_ivr_delay": "5",
            "double_layer_ivr_ext": "2",
            "over_use_credits": "0",
            "thank_you_url_redirect": "0",
            "show_autopopup_once_enabled": "0",
            "send_sms_after_one_minutes_call_enabled": "0",
            "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
            "name_enable": "0",
            "fallback_number_enabled": "0",
            "fallback_number_country_code": "",
            "fallback_number": null,
            "fallback_exten": null,
            "fallback_delay": "0",
            "call_scheduled_canceled_setting": "0",
            "modified": "2019-06-28 09:54:27",
            "updated": "2019-06-28 09:54:27",
            "salt": "322ce115245499ec9ee357e63a5e6860",
            "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
            "2fa": "0",
            "aging": "0",
            "password_modified": "2019-03-01 07:39:39",
            "password_modified_ip": null,
            "password_strength": null,
            "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
            "email_enable": "1",
            "outcome_tracking": "1",
            "scheduled_mail_daily_report": "0",
            "scheduled_email_report": "",
            "tracking_number": "0",
            "dont_show_widget_in_mobile": "1",
            "show_time_changes_schedule": "0",
            "company_outcomeweekly_summary": "1",
            "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
            "three_layer_ivr": "0",
            "three_layer_ivr_delay": "0",
            "three_layer_ivr_ext": "0",
            "automated_followup_call": "1",
            "api_forward_leademail": "1",
            "custom_widget_js_file": "0",
            "timeline_tracking": "0",
            "mime_type": "0"
          },
          "Lead": {
            "id": "461",
            "company_id": "2299",
            "widget_id": "2004",
            "referrer_id": "0",
            "agent_id": "59015",
            "called_agent_id": "59015",
            "vistors_id": "2170072713",
            "visitor_url_id": "0",
            "stratege_id": "0",
            "leadsource_id": "0",
            "department_id": "0",
            "session_id": null,
            "name": "",
            "username": "",
            "email": "",
            "fromemail": null,
            "phone_country": "91",
            "country_name": "IN",
            "phone": "+919646977254",
            "phone_type": "",
            "bookingno": null,
            "template_capture_number": "",
            "moveid": null,
            "requestid": null,
            "subject": "",
            "message": "",
            "contact_pref": "",
            "registration": "",
            "vehicle_details": "",
            "color": "",
            "price": "",
            "type": "0",
            "appointmentset": "0",
            "retry_strategy": "0",
            "location": "0",
            "missed": "1",
            "leadbody": "",
            "finalbody": "",
            "json_arr": "",
            "updated": "2019-06-01 13:46:45",
            "starttime": "13:46:01",
            "endtime": "13:46:23",
            "notify": "0",
            "notify_agent_id": "0",
            "no_click_notify": "0",
            "is_deleted": "0",
            "out_of_hours_status": "0",
            "revisit_checked": "0",
            "lead_revisits_counts": "0",
            "leadcallcompleted": "0",
            "leadcreated": "2019-06-01 13:39:55",
            "created": "2019-06-01 13:46:01",
            "sms_leads_logs_id": "0",
            "is_lead_type": "0",
            "full_name": null,
            "postcode": null,
            "country": null,
            "emailaddress": null,
            "question_first": null,
            "question_second": null,
            "question_third": null,
            "lead_reference_number": null,
            "distribution_ID": null,
            "lead_created_gmt": null,
            "Company": {
              "id": "2299",
              "cust": null,
              "cust_no": "0",
              "user_id": "0",
              "accents": "en-GB",
              "voice_type": "man",
              "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
              "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
              "twilio_account_status": "0",
              "plivo_accountsid": null,
              "plivo_auth_token": null,
              "plivo_account_status": "0",
              "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
              "api_type": "2",
              "carrier": "idt",
              "lead_carrier": "idt",
              "username": "",
              "name": "Pankaj Kumar",
              "first_name": "Pankaj",
              "last_name": "Kumar",
              "company_name": "ogs",
              "friendly_name": null,
              "date_created": null,
              "email": "pankaj@responseiq.com",
              "country_code": "44",
              "country_id": "225",
              "phone": "6463970198",
              "company_phone": "9646977254",
              "company_phone_country": "91",
              "company_extention": "1",
              "company_delay": "5",
              "title": "ogs",
              "website": "http://127.0.0.1:9999/test/widget",
              "password": "",
              "address_1": "",
              "zip_code": "",
              "logo": "",
              "call": "1",
              "country_of_business": "uk",
              "financial_email": "",
              "vat_number": null,
              "status": "1",
              "master_status": "1",
              "master_company_id": "0",
              "location_enable": "0",
              "department_enable": "0",
              "created": "2019-03-01 07:39:39",
              "is_trashed": "0",
              "is_type": "0",
              "selector": "Agent",
              "currency": "usd",
              "company_active_status": "1",
              "send_thanks_mail": "1",
              "step": "6",
              "manual_install": "0",
              "lead_revisit_alert": "0",
              "company_activation_reminder": "0",
              "became_customer": null,
              "three_call_missed": "0000-00-00 00:00:00",
              "agents_send_login_enabled": "0",
              "twilio_account_suspend": "1",
              "plivo_account_suspend": "1",
              "tc_confirmed": "1",
              "gdpr_enable": "0",
              "widget_country_code_edit": "1",
              "cancel_subscription_enable": "1",
              "api_enable": "1",
              "ilr_sms_module_enable": "0",
              "company_disable_widget": "0",
              "no_call_notify": "0",
              "company_daily_summary": "0",
              "company_weekly_summary": "0",
              "company_monthly_summary": "0",
              "company_daily_summary_emails": "pankaj@responseiq.com",
              "company_weekly_summary_emails": "pankaj@responseiq.com",
              "company_monthly_summary_emails": "pankaj@responseiq.com",
              "company_daily_csv": "0",
              "company_daily_csv_emails": "pankaj@responseiq.com",
              "company_disable_widget_ie": "0",
              "reply_by_sms_to_claim_email": "0",
              "google_analytics": "1",
              "querystring_enabled": "0",
              "company_plain_email_enabled": "0",
              "lead_name_play_enabled": "0",
              "is_attempts_to_exit_enabled": "0",
              "vodafone_requestid": "0",
              "country_multipliers": "1",
              "live_google_analytics": "1",
              "ilr_reports": "1",
              "double_layer_ivr": "1",
              "double_layer_ivr_delay": "5",
              "double_layer_ivr_ext": "2",
              "over_use_credits": "0",
              "thank_you_url_redirect": "0",
              "show_autopopup_once_enabled": "0",
              "send_sms_after_one_minutes_call_enabled": "0",
              "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
              "name_enable": "0",
              "fallback_number_enabled": "0",
              "fallback_number_country_code": "",
              "fallback_number": null,
              "fallback_exten": null,
              "fallback_delay": "0",
              "call_scheduled_canceled_setting": "0",
              "modified": "2019-06-28 09:54:27",
              "updated": "2019-06-28 09:54:27",
              "salt": "322ce115245499ec9ee357e63a5e6860",
              "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
              "2fa": "0",
              "aging": "0",
              "password_modified": "2019-03-01 07:39:39",
              "password_modified_ip": null,
              "password_strength": null,
              "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
              "email_enable": "1",
              "outcome_tracking": "1",
              "scheduled_mail_daily_report": "0",
              "scheduled_email_report": "",
              "tracking_number": "0",
              "dont_show_widget_in_mobile": "1",
              "show_time_changes_schedule": "0",
              "company_outcomeweekly_summary": "1",
              "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
              "three_layer_ivr": "0",
              "three_layer_ivr_delay": "0",
              "three_layer_ivr_ext": "0",
              "automated_followup_call": "1",
              "api_forward_leademail": "1",
              "custom_widget_js_file": "0",
              "timeline_tracking": "0",
              "mime_type": "0"
            },
            "Widget": {
              "id": "2004",
              "company_id": "2299",
              "widget_token": "9U3VLWE2R11P",
              "apikey": "RIQ130954042004",
              "title": "",
              "widget_url": "http://127.0.0.1:9999/test/widget",
              "logo": null,
              "port": "http://",
              "select_agent_or_team": "0",
              "status": "1",
              "install_status": "0",
              "installed_mail_sent": "1",
              "uninstall_mail_sent": "0",
              "installed_date": "2019-03-01 08:12:07",
              "visit_customize_status": "1",
              "agree_call_recording": "1",
              "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
              "welcometextschedule": null,
              "welcometext_animate_1": "0",
              "welcometextschedule_animate_2": "0",
              "widget_country_code_edit": "0",
              "created": "2019-03-01 07:40:37",
              "first_time_installation": "1",
              "call_schedule_button_mailsent": "1",
              "widgets_departments": "0",
              "visitor_time_call_enabled": "1",
              "visitor_duplicate_call_minutes": "1",
              "thank_you_url_redirect": "0",
              "thank_you_url": null,
              "webhook_url_port": "",
              "webhook_url": "",
              "webhook_events": "",
              "number_lookup": "2",
              "widget_file": "default"
            },
            "IqSource": [],
            "RetryResponseAttempt": [],
            "ScheduledCall": [
              {
                "id": "120877",
                "lead_id": "461",
                "company_id": "2299",
                "leadsource_id": "0",
                "widget_id": "2004",
                "agent_id": "59015",
                "scheduledby_id": "2299",
                "schdeuled_date": "2019-06-01",
                "scheduled_time": "13:45:00",
                "scheduled_time_text": null,
                "status": "1",
                "sms_sent_time": "0000-00-00 00:00:00",
                "sms_receive_time": "0000-00-00 00:00:00",
                "sent": "0",
                "follow_up_call": "1",
                "follow_up_call_mail": "0",
                "created": "2019-06-01 13:39:55"
              }
            ]
          },
          "Agent": {
            "id": "59015",
            "company_id": "2299",
            "call_order": "1",
            "first_name": "Ajay",
            "last_name": "",
            "user_name": "",
            "email": "ajay@responseiq.com",
            "phone_country": "44",
            "phone": "7355828310",
            "password": "",
            "image": "",
            "positions": "",
            "country": "",
            "state": "",
            "city": "",
            "zipcode": "",
            "address": "",
            "status": "1",
            "email_info": "0",
            "created": "2019-03-12 12:45:19",
            "mail_notify": "0",
            "extension_status": "0",
            "verify_status": "0",
            "verified_date": "0000-00-00 00:00:00",
            "extention": "321",
            "delay": "0",
            "agent_extention": null,
            "team_incoming_number_status": "0",
            "login_status": "0",
            "sms": "0",
            "access_level": "0",
            "last_login": "2019-03-12 12:45:19",
            "first_notification": "0000-00-00",
            "Company": {
              "id": "2299",
              "cust": null,
              "cust_no": "0",
              "user_id": "0",
              "accents": "en-GB",
              "voice_type": "man",
              "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
              "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
              "twilio_account_status": "0",
              "plivo_accountsid": null,
              "plivo_auth_token": null,
              "plivo_account_status": "0",
              "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
              "api_type": "2",
              "carrier": "idt",
              "lead_carrier": "idt",
              "username": "",
              "name": "Pankaj Kumar",
              "first_name": "Pankaj",
              "last_name": "Kumar",
              "company_name": "ogs",
              "friendly_name": null,
              "date_created": null,
              "email": "pankaj@responseiq.com",
              "country_code": "44",
              "country_id": "225",
              "phone": "6463970198",
              "company_phone": "9646977254",
              "company_phone_country": "91",
              "company_extention": "1",
              "company_delay": "5",
              "title": "ogs",
              "website": "http://127.0.0.1:9999/test/widget",
              "password": "",
              "address_1": "",
              "zip_code": "",
              "logo": "",
              "call": "1",
              "country_of_business": "uk",
              "financial_email": "",
              "vat_number": null,
              "status": "1",
              "master_status": "1",
              "master_company_id": "0",
              "location_enable": "0",
              "department_enable": "0",
              "created": "2019-03-01 07:39:39",
              "is_trashed": "0",
              "is_type": "0",
              "selector": "Agent",
              "currency": "usd",
              "company_active_status": "1",
              "send_thanks_mail": "1",
              "step": "6",
              "manual_install": "0",
              "lead_revisit_alert": "0",
              "company_activation_reminder": "0",
              "became_customer": null,
              "three_call_missed": "0000-00-00 00:00:00",
              "agents_send_login_enabled": "0",
              "twilio_account_suspend": "1",
              "plivo_account_suspend": "1",
              "tc_confirmed": "1",
              "gdpr_enable": "0",
              "widget_country_code_edit": "1",
              "cancel_subscription_enable": "1",
              "api_enable": "1",
              "ilr_sms_module_enable": "0",
              "company_disable_widget": "0",
              "no_call_notify": "0",
              "company_daily_summary": "0",
              "company_weekly_summary": "0",
              "company_monthly_summary": "0",
              "company_daily_summary_emails": "pankaj@responseiq.com",
              "company_weekly_summary_emails": "pankaj@responseiq.com",
              "company_monthly_summary_emails": "pankaj@responseiq.com",
              "company_daily_csv": "0",
              "company_daily_csv_emails": "pankaj@responseiq.com",
              "company_disable_widget_ie": "0",
              "reply_by_sms_to_claim_email": "0",
              "google_analytics": "1",
              "querystring_enabled": "0",
              "company_plain_email_enabled": "0",
              "lead_name_play_enabled": "0",
              "is_attempts_to_exit_enabled": "0",
              "vodafone_requestid": "0",
              "country_multipliers": "1",
              "live_google_analytics": "1",
              "ilr_reports": "1",
              "double_layer_ivr": "1",
              "double_layer_ivr_delay": "5",
              "double_layer_ivr_ext": "2",
              "over_use_credits": "0",
              "thank_you_url_redirect": "0",
              "show_autopopup_once_enabled": "0",
              "send_sms_after_one_minutes_call_enabled": "0",
              "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
              "name_enable": "0",
              "fallback_number_enabled": "0",
              "fallback_number_country_code": "",
              "fallback_number": null,
              "fallback_exten": null,
              "fallback_delay": "0",
              "call_scheduled_canceled_setting": "0",
              "modified": "2019-06-28 09:54:27",
              "updated": "2019-06-28 09:54:27",
              "salt": "322ce115245499ec9ee357e63a5e6860",
              "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
              "2fa": "0",
              "aging": "0",
              "password_modified": "2019-03-01 07:39:39",
              "password_modified_ip": null,
              "password_strength": null,
              "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
              "email_enable": "1",
              "outcome_tracking": "1",
              "scheduled_mail_daily_report": "0",
              "scheduled_email_report": "",
              "tracking_number": "0",
              "dont_show_widget_in_mobile": "1",
              "show_time_changes_schedule": "0",
              "company_outcomeweekly_summary": "1",
              "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
              "three_layer_ivr": "0",
              "three_layer_ivr_delay": "0",
              "three_layer_ivr_ext": "0",
              "automated_followup_call": "1",
              "api_forward_leademail": "1",
              "custom_widget_js_file": "0",
              "timeline_tracking": "0",
              "mime_type": "0"
            }
          }
        }
      ]
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072713",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559392213118",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15593922121629",
      "googleanalatics": "84710726.1559392223",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-01 13:54:27",
      "created": "2019-06-01 13:30:12",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "460",
      "company_id": "2299",
      "lead_id": "460",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072713",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559392213118",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "ffc36eb04627df558bfcfb453b4376b4",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/ffc36eb04627df558bfcfb453b4376b4",
      "parentcallsid": "34ac49360bab9fdba91ba6aff25f4d54",
      "dialcallstatus": "Connected",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "13",
      "last_response_time": "17",
      "missed": "1",
      "start_ringing_time": "2019-06-01 13:32:33",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-06-01 13:32:33",
      "created": "2019-06-01 13:32:07",
      "leadcreated": "2019-06-01 13:32:07",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "13",
      "lead_call_started": "2019-06-01 13:32:07",
      "lead_dial_call_status": "completed",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": "no",
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "460",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072713",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559392213118",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-06-01 13:32:33",
      "starttime": "13:32:07",
      "endtime": "13:32:20",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-06-01 13:32:07",
      "created": "2019-06-01 13:32:07",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072713",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559392213118",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15593922121629",
      "googleanalatics": "84710726.1559392223",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-06-01 13:54:27",
      "created": "2019-06-01 13:30:12",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "457",
      "company_id": "2299",
      "lead_id": "457",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072707",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559296778882",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "cf438112145cc0b6a8b29ca6ea8f4b2c",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/cf438112145cc0b6a8b29ca6ea8f4b2c",
      "parentcallsid": "7585bb4df496f79f346d5c74a3fcd6ed",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "13",
      "last_response_time": "18",
      "missed": "1",
      "start_ringing_time": "2019-05-31 12:17:58",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-05-31 12:18:10",
      "created": "2019-05-31 12:17:44",
      "leadcreated": "2019-05-31 12:17:44",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "13",
      "lead_call_started": "2019-05-31 12:17:44",
      "lead_dial_call_status": "agent-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "457",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072707",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559296778882",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-31 12:18:10",
      "starttime": "12:17:44",
      "endtime": "12:17:57",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-05-31 12:17:44",
      "created": "2019-05-31 12:17:44",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": null,
      "widget_id": null,
      "company_id": null,
      "visitor_url_id": null,
      "referrer_id": null,
      "session_id": null,
      "ipaddress": null,
      "clicks": null,
      "browser": null,
      "city": null,
      "device": null,
      "region": null,
      "countrycode": null,
      "countryname": null,
      "timezone": null,
      "timezone_name": null,
      "tooltip_close": null,
      "tooltip_close_click": null,
      "saveattempts_to_exit": null,
      "browsersession": null,
      "googleanalatics": null,
      "user_agent": null,
      "visitor_url": null,
      "querystring": null,
      "updated": null,
      "created": null
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "455",
      "company_id": "2299",
      "lead_id": "455",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072706",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559301126846",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "0fd3e9b8ee28ca77ec24ea82a4ffa18f",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/0fd3e9b8ee28ca77ec24ea82a4ffa18f",
      "parentcallsid": "73b40fb8af1bef6765ba88cf8cc4e4c1",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "14",
      "last_response_time": "22",
      "missed": "1",
      "start_ringing_time": "2019-05-31 12:12:43",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-05-31 12:12:57",
      "created": "2019-05-31 12:12:27",
      "leadcreated": "2019-05-31 12:12:27",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "14",
      "lead_call_started": "2019-05-31 12:12:27",
      "lead_dial_call_status": "agent-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "455",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072706",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559301126846",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+919646977254",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-31 12:12:57",
      "starttime": "12:12:27",
      "endtime": "12:12:41",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-05-31 12:12:27",
      "created": "2019-05-31 12:12:27",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072706",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559301126846",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559301126537",
      "googleanalatics": "1512104751.1559282790",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-05-31 12:12:27",
      "created": "2019-05-31 12:12:06",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "450",
      "company_id": "2299",
      "lead_id": "450",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072701",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559298429135",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "CAc0be717811064563735eb54aab0ff068",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "1",
      "response_time": "18",
      "last_response_time": "21",
      "missed": "1",
      "start_ringing_time": "2019-05-31 11:27:47",
      "end_ringing_time": "2019-05-31 11:27:53",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-05-31 11:27:54",
      "created": "2019-05-31 11:27:26",
      "leadcreated": "2019-05-31 11:27:26",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "19",
      "lead_call_started": "2019-05-31 11:27:26",
      "lead_dial_call_status": "no-answer",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "450",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072701",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559298429135",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-31 11:27:55",
      "starttime": "11:27:26",
      "endtime": "11:27:44",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-05-31 11:27:26",
      "created": "2019-05-31 11:27:26",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072701",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559298429135",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "1559298428284",
      "googleanalatics": "1310249097.1559298438",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-05-31 11:27:27",
      "created": "2019-05-31 11:27:08",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "449",
      "company_id": "2299",
      "lead_id": "449",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072700",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559296778882",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "CAae1cf804f63618f03e20ac287113439a",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "1",
      "response_time": "22",
      "last_response_time": "23",
      "missed": "1",
      "start_ringing_time": "2019-05-31 11:25:35",
      "end_ringing_time": "2019-05-31 11:25:43",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-05-31 11:25:44",
      "created": "2019-05-31 11:25:10",
      "leadcreated": "2019-05-31 11:25:10",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "23",
      "lead_call_started": "2019-05-31 11:25:10",
      "lead_dial_call_status": "no-answer",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": null,
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "449",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072700",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559296778882",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-31 11:25:44",
      "starttime": "11:25:10",
      "endtime": "11:25:32",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-05-31 11:25:10",
      "created": "2019-05-31 11:25:10",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072700",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559296778882",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15592982834464",
      "googleanalatics": "1057287262.1559282580",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-05-31 11:27:00",
      "created": "2019-05-31 11:24:44",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "447",
      "company_id": "2299",
      "lead_id": "447",
      "widget_id": "2004",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072699",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559296778882",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "4bdc607776688fe603ff55cdc9f58cd4",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/4bdc607776688fe603ff55cdc9f58cd4",
      "parentcallsid": "a94bc3beace02d8812cfb22dc678b084",
      "dialcallstatus": "MissedCustomer",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "21",
      "last_response_time": "25",
      "missed": "1",
      "start_ringing_time": "2019-05-31 11:02:52",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-05-31 11:03:11",
      "created": "2019-05-31 11:02:30",
      "leadcreated": "2019-05-31 11:02:30",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "21",
      "lead_call_started": "2019-05-31 11:02:30",
      "lead_dial_call_status": "agent-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "yes",
      "lead_hangupInitiated": null,
      "agent_call_status": "completed",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "447",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59015",
      "called_agent_id": "0",
      "vistors_id": "2170072699",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559296778882",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-31 11:03:12",
      "starttime": "11:02:30",
      "endtime": "11:02:51",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-05-31 11:02:30",
      "created": "2019-05-31 11:02:30",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": "59015",
      "company_id": "2299",
      "call_order": "1",
      "first_name": "Ajay",
      "last_name": "",
      "user_name": "",
      "email": "ajay@responseiq.com",
      "phone_country": "44",
      "phone": "7355828310",
      "password": "",
      "image": "",
      "positions": "",
      "country": "",
      "state": "",
      "city": "",
      "zipcode": "",
      "address": "",
      "status": "1",
      "email_info": "0",
      "created": "2019-03-12 12:45:19",
      "mail_notify": "0",
      "extension_status": "0",
      "verify_status": "0",
      "verified_date": "0000-00-00 00:00:00",
      "extention": "321",
      "delay": "0",
      "agent_extention": null,
      "team_incoming_number_status": "0",
      "login_status": "0",
      "sms": "0",
      "access_level": "0",
      "last_login": "2019-03-12 12:45:19",
      "first_notification": "0000-00-00",
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      }
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072699",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559296778882",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "",
      "countrycode": "IN",
      "countryname": "IN",
      "timezone": "Europe/London",
      "timezone_name": "Europe London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15592968124615",
      "googleanalatics": "1057287262.1559282580",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-05-31 11:02:31",
      "created": "2019-05-31 11:00:13",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "435",
      "company_id": "2299",
      "lead_id": "435",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072689",
      "visitor_url_id": "0",
      "referrer_id": "7379800",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559219721432",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": null,
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "ec07ac2fd5e302eec22e1ed98f641a66",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-05-30 13:49:01",
      "created": "2019-05-30 13:35:21",
      "leadcreated": "2019-05-30 13:35:21",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-05-30 13:35:21",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "busy",
      "agent_sip_status": "",
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": null,
      "riq_referrer": null,
      "riq_current_url": null
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "435",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "7379800",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072689",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": null,
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": null,
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-30 13:49:01",
      "starttime": "13:35:21",
      "endtime": "13:35:21",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-05-30 13:35:21",
      "created": "2019-05-30 13:35:21",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": "Pankaj kumar",
      "postcode": "160055",
      "country": "India",
      "emailaddress": "pankaj@responseiq.com",
      "question_first": "1",
      "question_second": "2",
      "question_third": "3",
      "lead_reference_number": "RVVVV77777",
      "distribution_ID": "23232323",
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072689",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559201920617",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15592019234542",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-05-30 08:39:00",
      "created": "2019-05-30 08:38:43",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": "7379800",
      "company_id": "2299",
      "type_id": "259508",
      "url": "API",
      "count": "1",
      "created": "2019-03-06 09:06:02",
      "RefferType": { "id": "259508", "company_id": "2299", "name": "API" }
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "434",
      "company_id": "2299",
      "lead_id": "434",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072689",
      "visitor_url_id": "0",
      "referrer_id": "7379800",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559218937159",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": null,
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "ba0f50f35c0c0db6dbf0f2c85ae3294b",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-05-30 13:26:26",
      "created": "2019-05-30 13:22:17",
      "leadcreated": "2019-05-30 13:22:17",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-05-30 13:22:17",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": "",
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": null,
      "riq_referrer": null,
      "riq_current_url": null
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "434",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "7379800",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072689",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": null,
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": null,
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-30 13:29:05",
      "starttime": "13:22:17",
      "endtime": "13:22:17",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "1",
      "leadcreated": "2019-05-30 13:22:17",
      "created": "2019-05-30 13:22:17",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": "Pankaj kumar",
      "postcode": "160055",
      "country": "India",
      "emailaddress": "pankaj@responseiq.com",
      "question_first": "1",
      "question_second": "2",
      "question_third": "3",
      "lead_reference_number": "RVVVV77777",
      "distribution_ID": "23232323",
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072689",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559201920617",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15592019234542",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-05-30 08:39:00",
      "created": "2019-05-30 08:38:43",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": "7379800",
      "company_id": "2299",
      "type_id": "259508",
      "url": "API",
      "count": "1",
      "created": "2019-03-06 09:06:02",
      "RefferType": { "id": "259508", "company_id": "2299", "name": "API" }
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "433",
      "company_id": "2299",
      "lead_id": "433",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072689",
      "visitor_url_id": "0",
      "referrer_id": "7379800",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559218515210",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": null,
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "754d24ee9e7f1b2105c7f59c13a04c92",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-05-30 13:29:01",
      "created": "2019-05-30 13:15:15",
      "leadcreated": "2019-05-30 13:15:15",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-05-30 13:15:15",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": "",
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": null,
      "riq_referrer": null,
      "riq_current_url": null
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "433",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "7379800",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072689",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": null,
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": null,
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-30 13:29:04",
      "starttime": "13:15:15",
      "endtime": "13:15:15",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-05-30 13:15:15",
      "created": "2019-05-30 13:15:15",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": "Pankaj kumar",
      "postcode": "160055",
      "country": "India",
      "emailaddress": "pankaj@responseiq.com",
      "question_first": "1",
      "question_second": "2",
      "question_third": "3",
      "lead_reference_number": "RVVVV77777",
      "distribution_ID": "23232323",
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072689",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559201920617",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15592019234542",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-05-30 08:39:00",
      "created": "2019-05-30 08:38:43",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": "7379800",
      "company_id": "2299",
      "type_id": "259508",
      "url": "API",
      "count": "1",
      "created": "2019-03-06 09:06:02",
      "RefferType": { "id": "259508", "company_id": "2299", "name": "API" }
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "432",
      "company_id": "2299",
      "lead_id": "432",
      "widget_id": "2004",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072689",
      "visitor_url_id": "0",
      "referrer_id": "7379800",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "155921765915",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": null,
      "recordingsid": "",
      "recordingurl": "",
      "parentcallsid": "de6298392391b3b79c8f8b68d39fd14f",
      "dialcallstatus": "MissedAgent",
      "dialcallduration": "0",
      "credit_used": "0",
      "response_time": "0",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "0000-00-00 00:00:00",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "0",
      "updated": "2019-05-30 13:14:01",
      "created": "2019-05-30 13:00:59",
      "leadcreated": "2019-05-30 13:00:59",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "0",
      "lead_call_started": "2019-05-30 13:00:59",
      "lead_dial_call_status": "",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": null,
      "lead_hangupInitiated": null,
      "agent_call_status": "no-answer",
      "agent_sip_status": "",
      "lead_sip_status": null,
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": null,
      "riq_referrer": null,
      "riq_current_url": null
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "432",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "7379800",
      "agent_id": "0",
      "called_agent_id": "0",
      "vistors_id": "2170072689",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": null,
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+918847216376",
      "phone_type": null,
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-30 13:14:05",
      "starttime": "13:00:59",
      "endtime": "13:00:59",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-05-30 13:00:59",
      "created": "2019-05-30 13:00:59",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": "Pankaj kumar",
      "postcode": "160055",
      "country": "India",
      "emailaddress": "pankaj@responseiq.com",
      "question_first": "1",
      "question_second": "2",
      "question_third": "3",
      "lead_reference_number": "RVVVV77777",
      "distribution_ID": "23232323",
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072689",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559201920617",
      "ipaddress": "f21403a74786192d8153bf5698e711bf",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "GB",
      "countrycode": "GB",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15592019234542",
      "googleanalatics": "517269665.1552900791",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-05-30 08:39:00",
      "created": "2019-05-30 08:38:43",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": "7379800",
      "company_id": "2299",
      "type_id": "259508",
      "url": "API",
      "count": "1",
      "created": "2019-03-06 09:06:02",
      "RefferType": { "id": "259508", "company_id": "2299", "name": "API" }
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  },
  {
    "Calllog": {
      "id": "431",
      "company_id": "2299",
      "lead_id": "431",
      "widget_id": "2004",
      "agent_id": "59052",
      "called_agent_id": "0",
      "vistors_id": "2170072693",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559210978944",
      "outcome_tracking_digit_id": "0",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "recordingsid": "696c253d69bd52330aacb2b6184df0f6",
      "recordingurl": "https://storage.googleapis.com/storage.responseiq.com/recordings/696c253d69bd52330aacb2b6184df0f6",
      "parentcallsid": "dbebdaf9b9718c07f77d315f58ac4310",
      "dialcallstatus": "Connected",
      "dialcallduration": "20",
      "credit_used": "2",
      "response_time": "89",
      "last_response_time": "0",
      "missed": "1",
      "start_ringing_time": "2019-05-30 11:12:10",
      "end_ringing_time": "0000-00-00 00:00:00",
      "is_deleted": "0",
      "total_credits": "2",
      "updated": "2019-05-30 11:14:09",
      "created": "2019-05-30 11:10:00",
      "leadcreated": "2019-05-30 11:10:00",
      "lead_type": "Live",
      "device_name": "Desktop",
      "note": null,
      "stratege_id": "0",
      "retry_strategy": "0",
      "retry_response_time": "95",
      "lead_call_started": "2019-05-30 11:10:00",
      "lead_dial_call_status": "lead-hangup",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "timelimitalert": "0",
      "agent_hangupInitiated": "no",
      "lead_hangupInitiated": "yes",
      "agent_call_status": "no-answer",
      "agent_sip_status": "",
      "lead_sip_status": "",
      "is_incoming_call": "0",
      "out_hour_call_scheduled": "0",
      "header_contact": "",
      "riq_referrer": "",
      "riq_current_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html"
    },
    "Company": {
      "id": "2299",
      "cust": null,
      "cust_no": "0",
      "user_id": "0",
      "accents": "en-GB",
      "voice_type": "man",
      "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
      "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
      "twilio_account_status": "0",
      "plivo_accountsid": null,
      "plivo_auth_token": null,
      "plivo_account_status": "0",
      "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
      "api_type": "2",
      "carrier": "idt",
      "lead_carrier": "idt",
      "username": "",
      "name": "Pankaj Kumar",
      "first_name": "Pankaj",
      "last_name": "Kumar",
      "company_name": "ogs",
      "friendly_name": null,
      "date_created": null,
      "email": "pankaj@responseiq.com",
      "country_code": "44",
      "country_id": "225",
      "phone": "6463970198",
      "company_phone": "9646977254",
      "company_phone_country": "91",
      "company_extention": "1",
      "company_delay": "5",
      "title": "ogs",
      "website": "http://127.0.0.1:9999/test/widget",
      "password": "",
      "address_1": "",
      "zip_code": "",
      "logo": "",
      "call": "1",
      "country_of_business": "uk",
      "financial_email": "",
      "vat_number": null,
      "status": "1",
      "master_status": "1",
      "master_company_id": "0",
      "location_enable": "0",
      "department_enable": "0",
      "created": "2019-03-01 07:39:39",
      "is_trashed": "0",
      "is_type": "0",
      "selector": "Agent",
      "currency": "usd",
      "company_active_status": "1",
      "send_thanks_mail": "1",
      "step": "6",
      "manual_install": "0",
      "lead_revisit_alert": "0",
      "company_activation_reminder": "0",
      "became_customer": null,
      "three_call_missed": "0000-00-00 00:00:00",
      "agents_send_login_enabled": "0",
      "twilio_account_suspend": "1",
      "plivo_account_suspend": "1",
      "tc_confirmed": "1",
      "gdpr_enable": "0",
      "widget_country_code_edit": "1",
      "cancel_subscription_enable": "1",
      "api_enable": "1",
      "ilr_sms_module_enable": "0",
      "company_disable_widget": "0",
      "no_call_notify": "0",
      "company_daily_summary": "0",
      "company_weekly_summary": "0",
      "company_monthly_summary": "0",
      "company_daily_summary_emails": "pankaj@responseiq.com",
      "company_weekly_summary_emails": "pankaj@responseiq.com",
      "company_monthly_summary_emails": "pankaj@responseiq.com",
      "company_daily_csv": "0",
      "company_daily_csv_emails": "pankaj@responseiq.com",
      "company_disable_widget_ie": "0",
      "reply_by_sms_to_claim_email": "0",
      "google_analytics": "1",
      "querystring_enabled": "0",
      "company_plain_email_enabled": "0",
      "lead_name_play_enabled": "0",
      "is_attempts_to_exit_enabled": "0",
      "vodafone_requestid": "0",
      "country_multipliers": "1",
      "live_google_analytics": "1",
      "ilr_reports": "1",
      "double_layer_ivr": "1",
      "double_layer_ivr_delay": "5",
      "double_layer_ivr_ext": "2",
      "over_use_credits": "0",
      "thank_you_url_redirect": "0",
      "show_autopopup_once_enabled": "0",
      "send_sms_after_one_minutes_call_enabled": "0",
      "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
      "name_enable": "0",
      "fallback_number_enabled": "0",
      "fallback_number_country_code": "",
      "fallback_number": null,
      "fallback_exten": null,
      "fallback_delay": "0",
      "call_scheduled_canceled_setting": "0",
      "modified": "2019-06-28 09:54:27",
      "updated": "2019-06-28 09:54:27",
      "salt": "322ce115245499ec9ee357e63a5e6860",
      "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
      "2fa": "0",
      "aging": "0",
      "password_modified": "2019-03-01 07:39:39",
      "password_modified_ip": null,
      "password_strength": null,
      "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
      "email_enable": "1",
      "outcome_tracking": "1",
      "scheduled_mail_daily_report": "0",
      "scheduled_email_report": "",
      "tracking_number": "0",
      "dont_show_widget_in_mobile": "1",
      "show_time_changes_schedule": "0",
      "company_outcomeweekly_summary": "1",
      "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
      "three_layer_ivr": "0",
      "three_layer_ivr_delay": "0",
      "three_layer_ivr_ext": "0",
      "automated_followup_call": "1",
      "api_forward_leademail": "1",
      "custom_widget_js_file": "0",
      "timeline_tracking": "0",
      "mime_type": "0"
    },
    "Lead": {
      "id": "431",
      "company_id": "2299",
      "widget_id": "2004",
      "referrer_id": "0",
      "agent_id": "59052",
      "called_agent_id": "0",
      "vistors_id": "2170072693",
      "visitor_url_id": "0",
      "stratege_id": "0",
      "leadsource_id": "0",
      "department_id": "0",
      "session_id": "1559210978944",
      "name": "",
      "username": "",
      "email": "",
      "fromemail": null,
      "phone_country": "91",
      "country_name": "IN",
      "phone": "+917355828310",
      "phone_type": "",
      "bookingno": null,
      "template_capture_number": "",
      "moveid": null,
      "requestid": null,
      "subject": "",
      "message": "",
      "contact_pref": "",
      "registration": "",
      "vehicle_details": "",
      "color": "",
      "price": "",
      "type": "0",
      "appointmentset": "0",
      "retry_strategy": "0",
      "location": "0",
      "missed": "1",
      "leadbody": "",
      "finalbody": "",
      "json_arr": "",
      "updated": "2019-05-30 11:14:09",
      "starttime": "11:10:00",
      "endtime": "11:11:29",
      "notify": "0",
      "notify_agent_id": "0",
      "no_click_notify": "0",
      "is_deleted": "0",
      "out_of_hours_status": "0",
      "revisit_checked": "0",
      "lead_revisits_counts": "0",
      "leadcallcompleted": "0",
      "leadcreated": "2019-05-30 11:10:00",
      "created": "2019-05-30 11:10:00",
      "sms_leads_logs_id": "0",
      "is_lead_type": "0",
      "full_name": null,
      "postcode": null,
      "country": null,
      "emailaddress": null,
      "question_first": null,
      "question_second": null,
      "question_third": null,
      "lead_reference_number": null,
      "distribution_ID": null,
      "lead_created_gmt": null,
      "Company": {
        "id": "2299",
        "cust": null,
        "cust_no": "0",
        "user_id": "0",
        "accents": "en-GB",
        "voice_type": "man",
        "twilio_accountsid": "AC02389cb02982ff141b7f275e0560d630",
        "twilio_auth_token": "55aa86f3c492129be472b57222df2fed",
        "twilio_account_status": "0",
        "plivo_accountsid": null,
        "plivo_auth_token": null,
        "plivo_account_status": "0",
        "asterisk_account_sid": "RIQAC582243a448ac1aec505ddb09128e49ea",
        "api_type": "2",
        "carrier": "idt",
        "lead_carrier": "idt",
        "username": "",
        "name": "Pankaj Kumar",
        "first_name": "Pankaj",
        "last_name": "Kumar",
        "company_name": "ogs",
        "friendly_name": null,
        "date_created": null,
        "email": "pankaj@responseiq.com",
        "country_code": "44",
        "country_id": "225",
        "phone": "6463970198",
        "company_phone": "9646977254",
        "company_phone_country": "91",
        "company_extention": "1",
        "company_delay": "5",
        "title": "ogs",
        "website": "http://127.0.0.1:9999/test/widget",
        "password": "",
        "address_1": "",
        "zip_code": "",
        "logo": "",
        "call": "1",
        "country_of_business": "uk",
        "financial_email": "",
        "vat_number": null,
        "status": "1",
        "master_status": "1",
        "master_company_id": "0",
        "location_enable": "0",
        "department_enable": "0",
        "created": "2019-03-01 07:39:39",
        "is_trashed": "0",
        "is_type": "0",
        "selector": "Agent",
        "currency": "usd",
        "company_active_status": "1",
        "send_thanks_mail": "1",
        "step": "6",
        "manual_install": "0",
        "lead_revisit_alert": "0",
        "company_activation_reminder": "0",
        "became_customer": null,
        "three_call_missed": "0000-00-00 00:00:00",
        "agents_send_login_enabled": "0",
        "twilio_account_suspend": "1",
        "plivo_account_suspend": "1",
        "tc_confirmed": "1",
        "gdpr_enable": "0",
        "widget_country_code_edit": "1",
        "cancel_subscription_enable": "1",
        "api_enable": "1",
        "ilr_sms_module_enable": "0",
        "company_disable_widget": "0",
        "no_call_notify": "0",
        "company_daily_summary": "0",
        "company_weekly_summary": "0",
        "company_monthly_summary": "0",
        "company_daily_summary_emails": "pankaj@responseiq.com",
        "company_weekly_summary_emails": "pankaj@responseiq.com",
        "company_monthly_summary_emails": "pankaj@responseiq.com",
        "company_daily_csv": "0",
        "company_daily_csv_emails": "pankaj@responseiq.com",
        "company_disable_widget_ie": "0",
        "reply_by_sms_to_claim_email": "0",
        "google_analytics": "1",
        "querystring_enabled": "0",
        "company_plain_email_enabled": "0",
        "lead_name_play_enabled": "0",
        "is_attempts_to_exit_enabled": "0",
        "vodafone_requestid": "0",
        "country_multipliers": "1",
        "live_google_analytics": "1",
        "ilr_reports": "1",
        "double_layer_ivr": "1",
        "double_layer_ivr_delay": "5",
        "double_layer_ivr_ext": "2",
        "over_use_credits": "0",
        "thank_you_url_redirect": "0",
        "show_autopopup_once_enabled": "0",
        "send_sms_after_one_minutes_call_enabled": "0",
        "send_sms_after_one_minutes_call_body": "It was great talking with you earlier! I just wanted to remind you I'm here if you need any further advice",
        "name_enable": "0",
        "fallback_number_enabled": "0",
        "fallback_number_country_code": "",
        "fallback_number": null,
        "fallback_exten": null,
        "fallback_delay": "0",
        "call_scheduled_canceled_setting": "0",
        "modified": "2019-06-28 09:54:27",
        "updated": "2019-06-28 09:54:27",
        "salt": "322ce115245499ec9ee357e63a5e6860",
        "secret": "1d3450530843620062d237e0edf902632060ffdc0fbe532b34249330dbf777dcbc80d43e239308e24386285b748f15fc000c7a8783c85eb1b15793f513385c8c",
        "2fa": "0",
        "aging": "0",
        "password_modified": "2019-03-01 07:39:39",
        "password_modified_ip": null,
        "password_strength": null,
        "api_auth_token": "a089055ad570fa165ff3c1cdbf16dcbc",
        "email_enable": "1",
        "outcome_tracking": "1",
        "scheduled_mail_daily_report": "0",
        "scheduled_email_report": "",
        "tracking_number": "0",
        "dont_show_widget_in_mobile": "1",
        "show_time_changes_schedule": "0",
        "company_outcomeweekly_summary": "1",
        "company_outcomeweekly_summary_emails": "pankaj.oditi@gmail.com,pankaj@responseiq.com",
        "three_layer_ivr": "0",
        "three_layer_ivr_delay": "0",
        "three_layer_ivr_ext": "0",
        "automated_followup_call": "1",
        "api_forward_leademail": "1",
        "custom_widget_js_file": "0",
        "timeline_tracking": "0",
        "mime_type": "0"
      },
      "Widget": {
        "id": "2004",
        "company_id": "2299",
        "widget_token": "9U3VLWE2R11P",
        "apikey": "RIQ130954042004",
        "title": "",
        "widget_url": "http://127.0.0.1:9999/test/widget",
        "logo": null,
        "port": "http://",
        "select_agent_or_team": "0",
        "status": "1",
        "install_status": "0",
        "installed_mail_sent": "1",
        "uninstall_mail_sent": "0",
        "installed_date": "2019-03-01 08:12:07",
        "visit_customize_status": "1",
        "agree_call_recording": "1",
        "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
        "welcometextschedule": null,
        "welcometext_animate_1": "0",
        "welcometextschedule_animate_2": "0",
        "widget_country_code_edit": "0",
        "created": "2019-03-01 07:40:37",
        "first_time_installation": "1",
        "call_schedule_button_mailsent": "1",
        "widgets_departments": "0",
        "visitor_time_call_enabled": "1",
        "visitor_duplicate_call_minutes": "1",
        "thank_you_url_redirect": "0",
        "thank_you_url": null,
        "webhook_url_port": "",
        "webhook_url": "",
        "webhook_events": "",
        "number_lookup": "2",
        "widget_file": "default"
      },
      "IqSource": [],
      "RetryResponseAttempt": [],
      "ScheduledCall": []
    },
    "Agent": {
      "id": null,
      "company_id": null,
      "call_order": null,
      "first_name": null,
      "last_name": null,
      "user_name": null,
      "email": null,
      "phone_country": null,
      "phone": null,
      "password": null,
      "image": null,
      "positions": null,
      "country": null,
      "state": null,
      "city": null,
      "zipcode": null,
      "address": null,
      "status": null,
      "email_info": null,
      "created": null,
      "mail_notify": null,
      "extension_status": null,
      "verify_status": null,
      "verified_date": null,
      "extention": null,
      "delay": null,
      "agent_extention": null,
      "team_incoming_number_status": null,
      "login_status": null,
      "sms": null,
      "access_level": null,
      "last_login": null,
      "first_notification": null
    },
    "IqSource": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "template_id": null,
      "alias": null,
      "company_name": null,
      "email": null,
      "delay": null,
      "note": null,
      "notifications_emails": null,
      "sms_template_id": null,
      "sms_forwarding_email": null,
      "sms_delay": null,
      "sms_feature_enabled": null,
      "status": null,
      "created": null,
      "is_deleted": null
    },
    "CompanySetting": {
      "id": null,
      "company_id": null,
      "package_id": null,
      "cancel_package_id": null,
      "package_cancel_success_failed_status": null,
      "next_renual_date": null,
      "last_payment_month": null,
      "stripe_customer_id": null,
      "stripe_subscription_id": null,
      "time_zone": null,
      "customer_message": null,
      "calling_limit": null,
      "sms_limit": null,
      "agent_limit": null,
      "used_calling_limit": null,
      "total_today_calls": null,
      "month_connected_call": null,
      "total_connected_call": null,
      "total_cost": null,
      "used_sms_limit": null,
      "monthly_fee": null,
      "sms_chat": null,
      "notification_status": null,
      "notifcation_sms_limit": null,
      "notifcation_calling_limit": null,
      "created": null,
      "email_notification_status": null,
      "monthly_summary": null,
      "daily_summary": null,
      "morning_daily_summary": null,
      "morning_daily_summary_status": null,
      "weekly_summary": null,
      "payment_setting": null,
      "notification_emails": null,
      "missed_iq_notification": null,
      "company_country": null,
      "send_mail_agent": null,
      "total_credit_charges": null,
      "duplicate_status": null,
      "duplicate": null,
      "daily_summary_report_status": null,
      "weekly_summary_report_status": null,
      "agent_extension_status": null,
      "send_agent_extension_status": null,
      "agent_notification_popup": null,
      "language": null,
      "agent_iqreport_access": null,
      "leadsource_quick_connect_access": null,
      "login_reminder": null,
      "morning_unclaimed_lead_reminders": null,
      "widget_message_setting": null,
      "customer_value": null,
      "is_customer_value": null,
      "currency": null,
      "closing_ratio": null,
      "daily_limit_verfied_numbers": null,
      "first_time_installed": null,
      "send_invoice_mail": null,
      "leads_enabled": null,
      "dynamicNumber_enabled": null,
      "became_customer": null,
      "three_call_missed_email": null,
      "roll_over_company_credits": null,
      "low_credits_email_notification": null,
      "low_credits_email_percentage_notification": null,
      "invoice_email_to_company_signup_email": null,
      "company_lead_number_callerid": null,
      "gdpr_phone_processing_enabled": null,
      "low_credit_notify_on": null,
      "total_over_use_credits": null,
      "keyword_cloud": null
    },
    "Widget": {
      "id": "2004",
      "company_id": "2299",
      "widget_token": "9U3VLWE2R11P",
      "apikey": "RIQ130954042004",
      "title": "",
      "widget_url": "http://127.0.0.1:9999/test/widget",
      "logo": null,
      "port": "http://",
      "select_agent_or_team": "0",
      "status": "1",
      "install_status": "0",
      "installed_mail_sent": "1",
      "uninstall_mail_sent": "0",
      "installed_date": "2019-03-01 08:12:07",
      "visit_customize_status": "1",
      "agree_call_recording": "1",
      "welcometext": "For a free & immediate callback, enter your number below and we will call you in 27 seconds.",
      "welcometextschedule": null,
      "welcometext_animate_1": "0",
      "welcometextschedule_animate_2": "0",
      "widget_country_code_edit": "0",
      "created": "2019-03-01 07:40:37",
      "first_time_installation": "1",
      "call_schedule_button_mailsent": "1",
      "widgets_departments": "0",
      "visitor_time_call_enabled": "1",
      "visitor_duplicate_call_minutes": "1",
      "thank_you_url_redirect": "0",
      "thank_you_url": null,
      "webhook_url_port": "",
      "webhook_url": "",
      "webhook_events": "",
      "number_lookup": "2",
      "widget_file": "default"
    },
    "LeadSource": {
      "id": null,
      "company_id": null,
      "template_id": null,
      "department_id": null,
      "company_name": null,
      "note": null,
      "recording_status": null,
      "select_agent_or_team": null,
      "email": null,
      "unknown": null,
      "forward_status": null,
      "autoforwarding_company_hours": null,
      "notification_emails": null,
      "is_deleted": null,
      "status": null,
      "notification_popup": null,
      "notification_delay": null,
      "created": null,
      "qualified_lead_status": null,
      "quick_connect_status": null,
      "totaliq": null
    },
    "Visitor": {
      "id": "2170072693",
      "widget_id": "2004",
      "company_id": "2299",
      "visitor_url_id": "0",
      "referrer_id": "0",
      "session_id": "1559210978944",
      "ipaddress": "492c3cc3bb4c0c817fdd286879affb38",
      "clicks": "0",
      "browser": "Chrome",
      "city": "",
      "device": "Desktop",
      "region": "IN",
      "countrycode": "IN",
      "countryname": "location",
      "timezone": "Europe/London",
      "timezone_name": "Europe/London",
      "tooltip_close": "0",
      "tooltip_close_click": "0",
      "saveattempts_to_exit": "1",
      "browsersession": "15592106133642",
      "googleanalatics": "965978483.1559196203",
      "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
      "visitor_url": "http://oditiwebs.com/ajaytestwidget-letteriq.html",
      "querystring": "",
      "updated": "2019-05-30 11:10:03",
      "created": "2019-05-30 11:03:33",
      "VisitorUrl": []
    },
    "CompanyDepartmentNumber": {
      "id": null,
      "company_id": null,
      "country_id": null,
      "name": null,
      "country_code": null,
      "phone": null,
      "ext": null,
      "delay": null,
      "is_deleted": null,
      "number_type": null,
      "company_delay_before_department": null,
      "created": null
    },
    "ReferrerUrl": {
      "id": null,
      "company_id": null,
      "type_id": null,
      "url": null,
      "count": null,
      "created": null
    },
    "OutcomeTrackingDigit": {
      "id": null,
      "company_id": null,
      "widget_id": null,
      "digits": null,
      "name": null,
      "is_deleted": null,
      "created": null
    }
  }
]
