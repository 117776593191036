import moment from 'moment';

export const getToday = () => {
  return moment();
};

export const getYesterday = () => {
  return moment().subtract(1, 'day');
};

export const getLastDates = (days = 7) => {
  return {
    startDate: moment().subtract(days, 'day'),
    endDate: moment(),
  };
};

export const getLastWeekDates = () => {
  return {
    startDate: moment()
      .subtract(1, 'weeks')
      .startOf('isoWeek'),
    endDate: moment()
      .subtract(1, 'weeks')
      .endOf('isoWeek'),
  };
};

export const getWeekDates = () => {
  return {
    startDate: moment().startOf('isoWeek'),
    endDate: moment(),
  };
};

export const getLastMonthDates = () => {
  return {
    startDate: moment().subtract(1, 'months'),
    endDate: moment(),
  };
};

export const getFormatedDate = (date, format = 'MM-DD-YYYY') =>
  moment(date).format(format);

export const getCurMonthDates = () => {
  return {
    startDate: moment().startOf('month'),
    endDate: moment(),
  };
};
